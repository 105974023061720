// Main APP Stylesheet
@import './constants.scss';
@import './mixins.scss';

.MuiContainer-root {
  padding: 0 !important;
}

body,
html {
  margin: 0;
  height: 100%;
  scroll-behavior: smooth;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  overflow: auto;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: inherit;
  }
}

html {
  overflow: hidden;
}

#root {
  height: 100%;
  max-width: 1920px;
  margin: 0 auto;
}

main {
  box-sizing: border-box;
  overflow-x: auto;
  width: 100%;
  position: relative;
  height: 100%;
  @media only screen and (min-width: $tablet) {
    min-height: 100vh;
  }
}

a,
.fake-link {
  @include linkStyles();
}

input,
textarea,
.select {
  -moz-appearance: none;
  -webkit-appearance: none;
  outline: none;
  font-family: inherit;
}

textarea {
  resize: none;
}

.error {
  color: $colorError;
  font-size: 0.6875rem;
  font-style: italic;
  font-weight: 300;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.align-center {
  display: flex;
  align-items: center;
}

.disabled {
  @include disabled();
}

.always-enabled {
  @include notDisabled();

  button {
    @include notDisabled();
  }
}

.desktop {
  display: none;
  @media only screen and (min-width: $desktop) {
    display: block;
  }
}

.mobile {
  display: block;
  @media only screen and (min-width: $desktop) {
    display: none;
  }
}

.login-page {
  a {
    font-weight: 600;
  }
}

@media print {
  body,
  html {
    background-color: $white;
    height: 100%;
    overflow: visible;
  }
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}
