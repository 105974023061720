@import '../../../styles/constants';

.token {
  &__name {
    font-size: 1.125rem;
    font-weight: 600;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__wrapper {
    height: 500px;
    overflow-y: scroll;
    margin: 16px 0 16px;
  }

  &__button {
    margin-right: 16px;
  }

  &__buttons {
    display: flex;
  }
}

@media only screen and (max-width: $desktop) {
  .token {
    &__header {
      flex-direction: column;
      align-items: flex-start;
    }

    &__buttons {
      flex-direction: column;
      width: 100%;
    }
  }
}
