@import '../../styles/constants.scss';
@import '../../styles/mixins.scss';
$cardSuccessColor: #3eb96f;
$cardErrorColor: #de1111;

.card {
  background: $white;
  border: 2px solid $colorCardBorder;
  box-sizing: border-box;
  box-shadow: $cardBoxShadow;
  border-radius: $borderRadius;
  position: relative;
  min-height: 80px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;

  &__wrapper {
    display: flex;
    text-align: center;
    align-items: center;
    cursor: pointer;
    padding: 24px 60px 25px 30px;
    box-sizing: border-box;
  }

  &__content {
    padding: 24px 30px 30px;
  }

  &__arrow {
    position: absolute;
    right: 3%;
    transform: rotate(180deg);
  }

  &__title {
    font-weight: bold;
    font-size: 1.285rem;
    margin-left: 30px;
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__content {
    border-top: 1px solid $colorCardBorder;
    padding-top: 20px;
  }

  &--collapsed {
    max-height: 80px;
    overflow: hidden;
    &:hover {
      cursor: pointer;
    }
    .card__arrow {
      transform: rotate(0deg);
    }
  }

  &--successfull {
    background: $colorSuccess;
    border: 2px solid $colorSuccess;
    .card__arrow path {
      stroke: $cardSuccessColor;
    }
    .card__title {
      color: $cardSuccessColor;
    }
  }

  &--warning {
    background: white;
    .card__arrow path {
      stroke: $colorWarning;
    }
    .card__title {
      color: black;
    }
  }

  &--failed {
    background: $colorFailed;
    border: 2px solid #f3e1de;
    .card__arrow path {
      stroke: $cardErrorColor;
    }
    .card__title {
      color: $cardErrorColor;
    }
  }

  &--disabled {
    @include disabled();
    opacity: 0.5;
  }
}

@media only screen and (max-width: $desktop) {
  .card {
    &__title {
      min-height: 28px;
    }
  }
}
