@import '../../../styles/constants.scss';

.uw-hub-page__income {
  margin-top: 32px;

  &-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &-tooltip-title {
      display: flex;
      flex-direction: column;
    }
  }

  &__title {
    font-size: 1.125rem;
    font-weight: 600;
    color: #101828;
  }

  &-calculators {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;

    .uw-hub-page__income-verification-method {
      font-weight: 600;
      font-size: 1rem;

      > span:first-of-type {
        margin-right: 6px;
      }
    }
  }

  &-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 24px;

    &:first-of-type {
      margin-top: 0;
    }

    .uw-hub-page__income-title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;

      > span {
        color: $black;
        font-weight: 600;
      }
    }
  }

  &-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 24px 24px;
    align-items: start;
    width: 100%;

    .input__inner {
      margin-bottom: 0;
    }

    .uw-hub-page__income-col {
      display: flex;
      flex-direction: column;
      min-width: 240px;
      margin-right: 28px;

      .uw-hub-page__income-label {
        font-size: 0.875rem;
        font-weight: 500;
        color: $colorLabel;
        margin-bottom: 6px;
      }

      .uw-hub-page__income-value {
        min-height: 44px;
        display: flex;
        align-items: center;
      }

      > span {
        font-weight: 500;
      }
    }
  }

  &-button {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
  }
}

@media only screen and (max-width: $desktop) {
  .uw-hub-page__income {
    margin-top: 16px;

    &-grid {
      grid-template-columns: 1fr 1fr;
    }
  }
}

@media only screen and (max-width: $tablet) {
  .uw-hub-page__income {
    &__title {
      font-size: 0.875rem;
    }

    &-calculators {
      flex-direction: column;
      align-items: flex-start;

      .uw-hub-page__income-verification-method {
        margin-bottom: 16px;
        font-size: 0.875rem;
      }

      > div {
        width: 100%;

        > button {
          width: 100%;
        }
      }
    }

    &-wrapper {
      .uw-hub-page__income-title {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;

        > button {
          margin-top: 16px;
          width: 100%;
        }
      }
    }

    &-grid {
      grid-template-columns: 1fr;
      grid-gap: 16px 0;

      .uw-hub-page__income-col {
        margin-right: 0;
      }
    }

    &-button {
      margin-top: 16px;
      justify-content: flex-start;

      > button {
        width: 100%;
      }
    }
  }
}
