@import '../../../styles/constants.scss';

.uw-hub-page__solar-contracts {
  margin-top: 32px;

  &-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
  }

  &-col {
    display: flex;
    flex-direction: column;
    min-width: 240px;
    margin-right: 28px;

    > span {
      font-weight: 500;
    }
  }

  &-label {
    font-size: 14px;
    font-weight: 500;
    color: $colorLabel;
    margin-bottom: 8px;
  }

  &-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 24px 24px;
    align-items: end;

    .input__inner {
      margin-bottom: 0;
    }
  }

  &-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
  }
}

@media only screen and (max-width: $desktop) {
  .uw-hub-page__solar-contracts {
    margin-top: 18px;

    &-col {
      min-width: min-content;
    }

    &-grid {
      grid-template-columns: 1fr 1fr;
    }
  }
}

@media only screen and (max-width: $tablet) {
  .uw-hub-page__solar-contracts {
    margin-top: 16px;

    &-row {
      flex-direction: column;
      margin-bottom: 0;
    }

    &-col {
      margin-bottom: 16px;
      margin-right: 0;
    }

    &-grid {
      grid-template-columns: 1fr;
      grid-gap: 16px 0;

      .uw-hub-page__solar-contracts-col {
        margin-bottom: 0;
      }
    }

    &-buttons {
      flex-direction: column;
      justify-content: flex-start;

      > button:first-of-type {
        margin-bottom: 16px;
      }
    }
  }
}
