@import '../../styles/constants';

.notifications {
  &__header {
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 600;
    color: $black;
    margin-bottom: 4px;
    &-wrapper {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $lighterGrey;
      padding-bottom: 20px;
      align-items: center;
      margin-bottom: 16px;
    }
  }
}
