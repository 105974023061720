@import '../../../../styles/constants.scss';

.notes {
  &__files {
    margin-bottom: 20px;

    &-item {
      background: $white;
      border: 0.5px solid $colorUpload;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 10px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      margin-top: 24px;
      font-size: 0.875rem;
      line-height: 17px;

      &-header {
        display: flex;
        align-items: center;
        :first-child {
          margin-right: 20px;
        }
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 24px;

    .file-upload {
      margin-right: 12px;
    }
  }

  &__svg {
    cursor: pointer;
  }
}
