.lazy-loader {
  position: relative;
  height: 100px;

  &--disabled {
    height: 0;
  }

  &__inner {
    text-align: center;
    margin-top: 12px;
  }

  .loader {
    margin: 0 auto;
  }
}
