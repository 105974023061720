@import '../../../../styles/constants';

.fund-info {
  &__input {
    &-wrapper {
      margin-top: 20px;
      display: flex;
      align-items: center;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-weight: 700;
    font-size: 0.875rem;
    color: $black;
    display: flex;
    align-items: center;
  }

  &__paper {
    padding: 24px;
    border: 1px solid $customGrey;
    border-radius: 12px;
    background: $white;
    margin-bottom: 24px;
    box-shadow: $redesignBoxShadow;
  }

  &__loan {
    &-title {
      color: $grey600;
      font-size: 1rem;
      font-weight: 600;
    }
    &-value {
      color: $black;
      font-size: 1rem;
      font-weight: 600;
      margin-left: 12px;
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    margin-top: 6px;
    flex-direction: row;
  }
  &__button {
    text-align: center;
    margin: 0 auto;
  }
  &__buttons {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    flex-direction: row;
  }
  &__checkbox {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }
  &__reversed {
    margin-top: 10px;
    display: flex;
    flex-direction: column;

    &-button {
      margin-right: 16px;
      width: 100%;
    }
    &__text {
      font-size: 0.875rem;
      margin-left: 20px;
    }
  }
  &__loan {
    &-wrapper {
      display: flex;
      flex-direction: column;
      margin-top: 30px;
    }
    &-amount {
      margin-top: 10px;
      opacity: 0.5;
    }
  }
  &__input {
    margin-right: 30px;
    white-space: nowrap;
  }
  .modal {
    &__container {
      max-height: 65%;
    }
  }
}

@media only screen and (max-width: $desktop) {
  .fund-info {
    &__reversed {
      &-button {
        margin: 12px 0;
        width: 100%;
      }
    }

    &__title {
      display: none;
    }
    &__paper {
      margin-top: 12px;
    }
    &__checkbox {
      margin-top: 15px;
    }
    &__buttons {
      margin-top: 0;
      display: flex;
      flex-direction: column;
    }
    &__wrapper {
      margin-bottom: 0;
      flex-direction: column;
    }
    &__loan {
      &-title {
        font-size: 0.875rem;
      }
      &-value {
        font-size: 0.875rem;
      }
    }
    &__input {
      &-wrapper {
        margin-top: 0;
        display: flex;
        flex-direction: column;
      }
    }

    &__button {
      margin-top: 10px;
    }
    .input {
      &-wrapper {
        margin-top: 5px;
      }
    }
  }
}
