@import '../../styles/constants';
@import '../../styles/mixins';

.flow {
  min-height: inherit;

  &__main {
    padding: 32px;
    display: flex;
  }

  &__title {
    font-weight: 600;
    font-size: 1.125rem;
    color: $colorLabel;
  }

  &__content {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    &--view-only {
      .button,
      button {
        @include disabled();
      }

      .modal {
        pointer-events: all;
        .button {
          pointer-events: all;
          opacity: 1;
          cursor: pointer;
        }
      }

      .file-upload {
        cursor: default;
        @include disabled();
      }

      .ntp-upload {
        cursor: default;
        @include disabled();
      }
    }
  }

  &__progress {
    margin-top: 20px;
  }

  &__buttons {
    .button:not(:first-child) {
      margin-left: 10px;
    }
  }

  .card {
    margin-bottom: 20px;
  }

  &__continue {
    font-size: 1.125rem;
    font-weight: bold;
    margin-top: 40px;
    text-align: right;
    cursor: pointer;
    color: $colorLabel;
    pointer-events: all;

    svg {
      transform: rotate(-90deg);
      width: 15px;
      height: 11px;
    }
  }

  .select-language {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;

    > div {
      width: 150px;
      margin-right: 30px;
    }
  }
}

.loan-status {
  border-radius: 4px;
  padding: 3px 6px;
  text-align: center;
  color: $white;

  &--active {
    background: #3eb96f;
  }

  &--canceled {
    background: $colorWarning;
  }

  &--on-hold {
    background: $colorWarning;
  }

  &--declined {
    background: #e35548;
  }

  &--loan-complete {
    background: #189ffb;
  }

  &--action-required {
    background: #e35548;
  }
}

.address-overriden-warning {
  display: grid;
  column-gap: 10px;
  grid-template-columns: 30px auto;
  align-items: center;
  margin-bottom: 15px;
  font-size: 0.75rem;
  font-weight: 400;

  .warning-icon {
    background: $colorWarning;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    position: relative;
    &:after {
      content: '';
      background: url('/assets/danger-triangle-white.svg') no-repeat 50%;
      width: 18px;
      height: 18px;
      position: absolute;
      top: 2px;
      left: 3px;
      background-size: contain;
    }
  }
}

.paper {
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  border: 1px solid #f2f4f7;
  padding: 24px;
  border-radius: 12px;
}

.paper-v2 {
  padding: 0;
  margin-bottom: 16px;
  border-radius: 12px;
}

@media only screen and (max-width: $desktop) {
  .flow {
    min-height: 100vh;
    padding: 0 0 24px;

    &__main {
      margin: 0;
      display: flex;
      flex-direction: column;
    }
    &__content {
      margin: 0;
    }
    &__buttons {
      justify-content: center;
    }
    &__progress {
      width: 100%;
      padding: 0;
      margin-bottom: 100px;
    }
  }
}

@media only screen and (max-width: $tablet) {
  .flow {
    &__main {
      padding: 24px;
    }

    &__buttons {
      margin-bottom: 60px;
    }

    .select-language {
      > div {
        width: 100%;
        margin-right: 0;
      }
    }
  }
}

@media only screen and (max-width: $mobileLarge) {
  .flow {
    &__main {
      padding: 24px 16px;
    }
    [data-step='LOAN_DOCUMENTS'] {
      padding: 24px 0;
    }
  }
}
