@import '../../../../styles/constants';

.cardTitle {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  font-weight: 600;
}
.cardContentContainer {
  padding: 0 24px 0;
}

.cardContentText {
  margin-top: 24px;
  margin-bottom: 24px;
  font-size: 0.875rem;
}

.cardTitleBigger {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  font-weight: 600;
  font-size: 1rem;
  color: $black;
}

.cardContent {
  display: grid;
  row-gap: 16px;
  width: 100%;
}

.status {
  display: flex;
  align-items: center;
  margin-left: 4px;
}

.cardButtons {
  display: flex;
  justify-self: flex-end;
}

.informationForm {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}

.achErrors {
  color: $colorError;
  font-weight: 600;
  font-size: 0.875rem;
}

@media only screen and (max-width: $desktop) {
  .informationForm {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: $tablet) {
  .informationForm {
    grid-template-columns: repeat(1, 1fr);
  }

  .cardContentContainer {
    padding: 0 16px 0;
  }

  .cardContentText {
    margin-top: 16px;
    margin-bottom: 12px;
  }

  .cardTitleBigger {
    flex-direction: column;
    align-items: start;
  }

  .status {
    margin-top: 8px;
  }
}
