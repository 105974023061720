@import '../../../styles/constants';

.congrats-step {
  width: 100%;
  text-align: center;
  background: $white;
  border: 2px solid $colorCardBorder;
  box-shadow: $cardBoxShadow;
  border-radius: $borderRadius;
  padding-top: 100px;

  h2 {
    font-family: inherit;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 2.25rem;
    color: $checkboxLabel;
    letter-spacing: 0.1em;
  }

  h3 {
    font-family: inherit;
    font-size: 1.125rem;
    font-weight: 400;
    color: $checkboxLabel;
    line-height: 1.4;
    margin-bottom: 60px;
  }

  .button {
    width: 165px;
  }
}

@media screen and (max-width: $desktop) {
  .congrats-step {
    padding: 40px 10px;
    margin: 0 50px;

    svg {
      width: 80%;
    }
  }
}

@media screen and (max-width: $tablet) {
  .congrats-step h2 {
    font-size: 1.7rem;
  }
}
