@import './constants.scss';

.not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $white;
  padding: 20px;
  height: 100vh;
  flex-direction: column;
  &__title {
    font-size: 3.75rem;
    margin-bottom: 24px;
    line-height: 72px; /* 120% */
    letter-spacing: -1.2px;
    margin-top: 24px;
    font-weight: 700;
    color: var(--gray-900, var(--color-gray-900, #101828));
  }
  &__subtitle {
    font-size: 1.25rem;
    margin-bottom: 48px;
    text-align: center;
    line-height: 30px;
  }
}

// Media queries for responsive design
@media only screen and (max-width: $tablet) {
  .not-found {
    padding: 10px;
  }
  .not-found__container {
    padding: 20px;
  }
  .not-found__title {
    font-size: 2.25rem;
  }
  .not-found__subtitle {
    font-size: 1.125rem;
    line-height: 28px;
  }
}
