@import '../../../styles/constants.scss';

.uw-hub-page__notes {
  margin-top: 32px;

  &-wrapper {
    width: 100%;
  }

  &-list {
    width: 100%;
    margin-top: 16px;

    > span {
      font-weight: 700;
      padding: 0 10px 0 10px;
    }

    .notes__items {
      margin-top: 0;
    }
  }

  &-new-note {
    width: 100%;

    .notes__buttons {
      button {
        min-width: 170px;
      }
    }
  }
}

@media only screen and (max-width: $tablet) {
  .uw-hub-page__notes {
    margin-top: 16px;

    .notes__buttons {
      flex-direction: column;

      .file-upload {
        width: 100%;
        margin-right: 0;
      }

      button {
        width: 100%;
      }

      > button {
        margin-top: 12px;
      }
    }
  }
}
