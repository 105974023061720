@import '../../../../styles/constants';

.cardTitle {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  font-weight: 600;
}

.cardContentContainer {
  padding: 12px 24px 0;
}

.cardText {
  font-size: 0.875rem;
}

.cardTitleBigger {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  font-weight: 600;
  font-size: 1rem;
  color: $black;
}

.cardContent {
  display: grid;
  row-gap: 16px;
  width: 100%;
}

.status {
  display: flex;
  align-items: center;
  margin-left: 4px;
}

.cardButtons {
  display: flex;
  justify-self: flex-end;
}

@media only screen and (max-width: $tablet) {
  .cardContentContainer {
    padding: 0 16px 0;
  }

  .cardTitleBigger {
    flex-direction: column;
    align-items: start;
  }

  .status {
    margin-top: 8px;
  }
}
