@import '../../styles/constants';

.uw-hub-page {
  padding: 32px;
  .MuiCardHeader-title {
    color: #101828;
  }
}

@media screen and (max-width: $sm) {
  .uw-hub-page {
    padding: 32px 5px;
  }
}
