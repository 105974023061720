@import './constants.scss';

.release-notes {
  height: 100%;
  padding: 100px;

  &__container {
    background: $white;
    border-radius: 20px;
    border: 2px solid $colorCardBorder;
    box-sizing: border-box;
    box-shadow: $cardBoxShadow;
    padding: 30px;
    margin-top: 30px;

    &--full-height {
      height: 100vh;
    }
  }

  &-content {
    margin-bottom: 60px;
    margin-top: 60px;
  }
}

@media only screen and (max-width: $desktop) {
  .release-notes {
    padding: 20px;
  }
}
