@import '../../../../styles/constants.scss';

.support {
  &__card {
    border-radius: 8px;
    border: 1px solid $lighterGrey;
    background: $white;
    padding: 16px 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-right: 12px;
    min-width: 240px;

    box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06),
      0px 4px 8px -2px rgba(16, 24, 40, 0.1);
  }

  &__text {
    color: $grey600;
    font-size: 1rem;
    font-weight: 600;
    &-wrapper {
      display: flex;
    }
  }

  &__cards {
    display: flex;
    justify-content: space-between;
  }

  &__value {
    color: $black;
    margin-top: 10px;
    font-size: 0.875;
    font-weight: 600;
  }
}

@media only screen and (max-width: $desktop) {
  .support {
    &__cards {
      flex-direction: column;
    }
    &__card {
      margin-top: 16px;
    }
  }
}
