@import '../../../styles/constants';

.aus-decision {
  width: 100%;
  flex-direction: column;
  position: relative;
  flex-wrap: wrap;
  &--nowrap {
    flex-wrap: inherit;
  }

  &__icons {
    display: flex;
    align-items: center;
    position: relative;
    flex-shrink: 0;
  }

  &__icon {
    width: 27px;
    height: 27px;
    background-color: $colorButton;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    cursor: pointer;
    min-width: 27px;
    &:hover {
      background-color: $colorLabel;
      circle {
        fill: $colorLabel;
      }
    }
  }

  &__arrow {
    width: 10px;
    margin-left: -1px;
    &--down {
      margin-left: 0;
      margin-top: -1px;
    }
    path {
      fill: white;
    }
  }

  &__plus {
    width: 80%;
    height: 80%;
  }

  &__hamburger {
    width: 15px;
    path {
      stroke-width: 3px;
    }
  }

  &__child {
    margin-top: 20px;
    width: 100%;
  }

  &__field-title {
    text-align: center;
    font-weight: 600;
    margin-bottom: 2px;
    color: $colorLabel;
  }

  &__element {
    box-shadow: 0px 1px 2px 0px #1018280f;
    border-radius: 12px;
    background: $white;
    border: 1px solid #f9fafb;
    padding: 24px;
    margin-bottom: 20px;
    &-content {
      padding-left: 20px;
    }
    &-fields {
      display: flex;
      align-items: center;
    }
    &-search {
      display: flex;
      margin-right: 24px;
    }
  }
}

.dnd-list {
  &__item {
    margin-bottom: 32px;
  }
}

@media only screen and (max-width: $tablet) {
  .aus-decision {
    flex-direction: column;
    &__element {
      padding: 24px;
      &-fields {
        display: block;
      }
      &-search {
        margin-bottom: 16px;
      }
    }

    &__icons {
      align-items: flex-start;
      width: 100%;
    }
  }
}
