@import '../../styles/constants.scss';

$calendarBoxShadow: 0px 20px 24px -4px #10182814;
$calendarTextColor: #344054;
$calendarBorderColor: #f5f5f5;
$arrowColor: #6e6e6e;

.dayStyles {
  border-radius: 50%;
  background-color: $blue;
  color: $white !important;
}

// styles for the calendar when it has the custom ranges
.calendar-wrapper {
  padding: 0;
  background: $white;
  display: flex;
  border-radius: 8px;
  box-shadow: $calendarBoxShadow;
  border: 1px solid $calendarBorderColor;

  &__custom-ranges {
    height: 100%;
    display: grid;
    gap: 8px;
    padding: 16px 8px;
    position: relative;
  }

  .react-datepicker {
    border: 0;
    border-left: 1px solid $calendarBorderColor;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: none;
  }
}

.react-datepicker {
  font-size: 1rem;
  box-shadow: $calendarBoxShadow;
  border-radius: 8px;
  border: 1px solid $calendarBorderColor;
  padding: 16px;

  &__header {
    background-color: $white;
    border-bottom: 0;
    font-weight: 600;

    &__dropdown {
      &--scroll {
        height: 26px;
        background: transparent;
        margin-top: 13px;
        font-size: 0.875rem;
        font-weight: 500;
      }
    }
  }

  &__tab-loop {
    z-index: $zIndexFixedElements;
  }

  &__day-names {
    margin-top: 20px;
    display: inline-flex;
  }

  &__day-name {
    color: $calendarTextColor;
    width: 32px;
  }

  &__day {
    width: 32px;
    line-height: 24px;
    color: $calendarTextColor;
    text-align: center;
    padding: 4px;

    &--in-selecting-range:not(&--in-range) {
      @extend .dayStyles;
    }

    &--outside-month {
      cursor: initial;
      pointer-events: none;
      color: transparent;
      background-color: transparent !important;
    }

    &--keyboard-selected {
      @extend .dayStyles;
      &:hover {
        @extend .dayStyles;
      }
    }

    &--disabled {
      cursor: default;
      color: #ccc;
    }

    &--in-range,
    &--selected {
      @extend .dayStyles;

      &:hover {
        @extend .dayStyles;
      }
    }

    &:hover {
      border-radius: 50%;
    }
  }

  &__month-dropdown,
  &__year-dropdown {
    color: $calendarTextColor;
    border-radius: 8px;
    border: 1px solid $calendarBorderColor;
    font-weight: 500;
    background-color: $white;
    box-shadow: 0 20px 24px -4px #10182814;
    margin-top: 8px;
  }
  &__month-option,
  &__year-option {
    margin-bottom: 4px;
    padding: 4px;
    &--selected {
      display: none;
    }

    &:hover {
      background-color: $blue;
      color: $white;
    }
  }

  &__year-read-view,
  &__month-read-view {
    color: $calendarTextColor;
    visibility: initial !important;
  }

  // Navigation arrows
  &__month-read-view--down-arrow,
  &__year-read-view--down-arrow {
    top: 4px;
    border-color: $arrowColor !important;
    border-width: 1.5px 1.5px 0 0;
  }

  &__navigation-icon::before {
    border-color: $arrowColor;
    border-width: 2px 2px 0 0;
  }

  .react-datepicker__navigation--next,
  .react-datepicker__navigation--previous {
    top: 22px;
  }

  &__navigation {
    &--years-previous,
    &--years-upcoming {
      border-color: $arrowColor;
      border-width: 2px 2px 0 0;
      transform: rotate(135deg);
      border-style: solid;
      content: '';
      display: block;
      height: 9px;
      top: 0;
      width: 9px;
      margin-top: 6px;
      margin-bottom: 8px;
    }
    &--years-upcoming {
      transform: rotate(-45deg);
      margin-top: 8px;
      margin-bottom: 6px;
    }
  }
}

@media only screen and (max-width: $tablet) {
  .react-datepicker-wrapper {
    width: 100%;
  }

  .calendar-wrapper {
    flex-direction: column;
    margin: 0 12px;

    &__custom-ranges {
      grid-template-columns: 1fr 1fr 1fr;
      padding: 8px 0 0 0;
    }

    .react-datepicker {
      font-size: 0.75rem;
      padding-top: 0;

      &__month-read-view--down-arrow,
      &__year-read-view--down-arrow {
        top: 4px;
      }

      &__header__dropdown--scroll {
        height: 0;
      }
    }
  }
}
