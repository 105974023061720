@import '../../styles/constants';

.playground {
  padding: 0 50px;
  box-sizing: border-box;
  max-width: 100%;
  overflow: hidden;
  background: $colorBackground;

  .playground__cols {
    width: 100%;
    display: flex;
  }
  h3 {
    margin-top: 30px;
  }
  &__col {
    width: 48%;
    &:first-child {
      margin-right: 4%;
    }
  }
}

@media only screen and (max-width: $desktop) {
  .playground {
    padding: 0 15px;
  }
}
