@import '../../../../styles/constants.scss';

.faq {
  &__title {
    color: $black;
    width: 100%;
    font-size: 1.125rem;
    font-weight: 500;
    padding: 20px 10px 20px 0;
  }

  &__header {
    font-weight: 700;
    font-size: 0.875rem;
    color: $black;
    margin-top: 20px;
    margin-left: 2px;
    display: flex;
    align-items: center;
  }

  &__question {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__wrapper {
    border-bottom: 1px solid $lighterGrey;
  }

  &__text {
    color: $grey600;
    font-size: 1rem;
    font-weight: 400;
    padding: 0 10px 10px;
    max-width: 800px;
  }
}

@media only screen and (max-width: $desktop) {
  .faq {
    &__header {
      display: none;
    }
  }
}
