@import '../../../styles/constants.scss';

.uw-hub-page__property-title {
  margin-top: 32px;

  &-header {
    font-size: 1.125rem;
    font-weight: 600;
  }

  &-item {
    font-size: 0.75rem;

    > span:last-of-type {
      margin-left: 12px;
      font-weight: 500;
      font-size: 0.875rem;
    }
  }
}

@media only screen and (max-width: $desktop) {
  .uw-hub-page__property-title {
    margin-top: 16px;
  }
}
