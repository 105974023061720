@import '../../styles/constants';

$dashColor: #ccd8ff;

.file-upload {
  cursor: pointer;
  width: 100%;

  &--active {
    border: 1px dashed #d1d1d1;
    border-radius: 10px;
  }
}

.file-upload-v2 {
  margin-top: 0;
}

@media only screen and (max-width: $desktop) {
  .file-upload {
    margin-top: 10px;
  }
}

.upload-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  font-size: 0.875rem;
  text-align: center;

  &:hover {
    border: 2px solid $blue;
  }

  &__cta {
    font-weight: 600;
    color: $primary700;
  }

  &__file-extensions {
    font-size: 0.75rem;
  }

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: $black;

    &__buttons {
      display: flex;
      flex-shrink: 0;
    }
  }

  &__dropzone {
    display: flex;
    flex-direction: column;
    background: $lightBlue;
    width: 100%;
    border: 1px dashed $dashColor;
    padding: 16px;
    margin-bottom: 12px;
  }

  &--no-border {
    &:hover {
      border: none;
    }
  }
}

@media screen and (max-width: 600px) {
  .upload-card {
    &__title {
      flex-wrap: wrap;

      &__buttons {
        width: 100%;
        justify-content: center;
      }

      button {
        width: 140px;
        padding-top: 8px;
        padding-bottom: 8px;
        margin-top: 16px;
      }
    }
  }
}

@media screen and (max-width: $mobileLarge) {
  .upload-card {
    &__title {
      button {
        width: 100%;
      }
    }
  }
}
