@import '../../../styles/constants.scss';

.uw-hub-page__loan-app-details {
  margin-bottom: 32px;

  &-loan-product {
    margin-right: 12px;
  }

  &-icons {
    display: flex;
    justify-content: space-between;
  }

  &-loan-product-content {
    font-size: 1rem;
    font-weight: 500;

    > span:first-of-type {
      font-weight: 700;
      padding-right: 6px;
    }
  }

  &-state-details {
    margin: 34px 0 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &-applicants {
    display: flex;
    flex-direction: column;

    &-name {
      font-size: 1.125rem;
      font-weight: 600;
      color: #101828;
      line-height: 1.55;
      margin-bottom: 4px;
    }
  }

  &-service-address {
    font-size: 0.875rem;
  }

  &-state {
    display: flex;
    flex-direction: row;

    > button:first-of-type {
      margin-right: 12px;
    }
  }

  &-next-state-button {
    position: relative;

    > button {
      width: 100%;
    }
  }

  &-next-state-dialog {
    position: absolute;
    width: 100%;

    > button {
      margin-top: 6px;
      width: 100%;

      &:hover {
        background-color: $lighterGrey;
      }
    }
  }
}

@media only screen and (max-width: $desktop) {
  .uw-hub-page__loan-app-details {
    margin-bottom: 16px;

    &-loan-product {
      font-size: 0.75rem;
    }

    &-applicants {
      margin-bottom: 16px;
    }

    &-service-address {
      font-size: 0.875rem;
    }

    &-state-details {
      flex-direction: column;
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }
}

@media only screen and (max-width: $tablet) {
  .uw-hub-page__loan-app-details {
    &-icons {
      flex-direction: column;
    }

    &-loan-product {
      margin-right: 0;
      margin-bottom: 12px;
    }

    &-state-details {
      margin-top: 12px;
      margin-bottom: 24px;
    }

    &__wrapper {
      padding: 0 10px;
    }

    &-applicants {
      margin-bottom: 12px;
    }

    &-state {
      flex-direction: column;

      > button:first-of-type {
        margin-bottom: 12px;
        margin-right: 0;
      }
    }
  }
}
