@import '../../../styles/constants.scss';

.uw-hub-page__evaluation {
  margin-top: 32px;

  &-content {
    width: 100%;
  }

  &-history {
    margin-top: 16px;
    width: 100%;

    &-item {
      width: 100%;
    }
  }

  &-session {
    width: 100%;

    &-item {
      margin-bottom: 16px;
    }

    &-item:last-of-type {
      margin-bottom: 0;
    }
  }

  &-loan-header {
    display: flex;
    justify-content: space-between;
    margin-right: 16px;

    > span {
      font-size: 0.875rem;
      font-weight: 600;
    }

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

@media only screen and (max-width: $desktop) {
  .uw-hub-page__evaluation {
    margin-top: 16px;
  }
}

@media only screen and (max-width: $tablet) {
  .uw-hub-page__evaluation-history {
    margin-top: 0;
  }

  .uw-hub-page__evaluation-session-item {
    margin-bottom: 0;
  }
}
