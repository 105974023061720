@import '../../../styles/constants';

$selectedBackground: #f2f9fc;

.loan-document {
  &__wrapper {
    max-width: 1400px;
    margin: 20px auto;
    padding: 50px;
  }

  &__created {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    display: flex;
    background: $selectedBackground;
  }

  &__form {
    &-add {
      display: flex;
      align-items: 'center';
      padding-left: 70px;
      padding-bottom: 20px;
    }

    &-save {
      display: flex;
      justify-content: center;
      margin-top: 10px;
      align-items: center;
    }
  }

  &__actions {
    padding-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $selectedBackground;
    margin: 0;
    padding: 10px 20px;
  }

  &__input {
    margin-top: 0;
    margin-right: 40px;
  }

  &__header {
    .button {
      margin: 0 5px;

      &__text {
        padding: 6px;
      }
      &:first-child {
        background: $white;
        border: 1px solid $colorNotesBorder;
      }
    }
  }
}

@media only screen and (max-width: $tablet) {
  .loan-document {
    &__wrapper {
      padding: 20px;
    }
  }
}
