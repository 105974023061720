@import '../../styles/constants.scss';

.select-role {
  padding-left: 24px;
  max-height: 250px;
  overflow-y: auto;

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 8px;

    &:hover {
      background-color: #f2f4f7;
    }
  }

  &__item-org {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  &__item-role {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
}
