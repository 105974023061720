// Define re-usable constants and media queries here

// Fonts
$fontSize: 16px;
$fontSizeSecondary: 0.875rem;

// Breakpoints
$laptop: 1440px;
$desktop: 1024px;
$tablet: 768px;
$mobileLarge: 480px;
$mobile: 375px;

// MUI Breakpoints
$xs: 0px;
$sm: 600px;
$md: 900px;
$lg: 1200px;
$xl: 1536px;

// Text
$lightTextColor: #fafafa;

// Gradients
$headerGradient: linear-gradient(90deg, #13547a 0%, #80d0c7 100%);

// Colors
$white: #fff;
$black: #000;
$grey: #c4c4c4;
$colorButton: #1c8ebf;
$colorButtonHover: #236582;
$colorLabel: #667085;
$colorTitle: #236582;
$colorDisabled: #bfbfbf;
$colorBackground: #e6f1f2;
$selectedBackground: #f2f9fc;
$colorCardBorder: #f2f4f5;
$checkboxLabel: #46535a;
$colorSuccess: #e2f9f6;
$colorSuccessV2: #6ce9a6;
$colorFailed: #f7e8e6;
$colorWarning: #ffdc5e;
$colorWarningBackground: #ffdc5e33;
$colorError: #ff3b00;
$colorDelete: #e35548;
$colorHoverDelete: #b55248;
$alertsRed: #f8eeec;
$colorIncompleteStatus: #ffecec;
$colorSuccess: #d3f1ed;
$colorSuccessSecondary: #46d0cc;
$colorToolTipText: #1c8ebf;
$colorNotesBorder: #a4e2fd;
$colorIdentitySideModal: #74c8ed;
$colorScroll: #74c8ed;
$colorUpload: #79beda;
$colorFlowBackground: #fcfcfd;
$colorEmail: #828282;
$colorSuspended: #f3e566;
$colorSuspendedText: #ac9c08;
$colorPending: #bfd0fd;
$colorNotifTextPositive: #027a48;
$colorNotifTextNegative: #b42318;
$colorNotifTextWarning: #b54708;
$colorNotifLearnMoreSuccess: #039855;
$colorNotifLearnMoreNegative: #d92d20;
$colorNotifLearnMoreWarning: #dc6803;
$colorNotifBackgroundPositive: #f6fef9;
$colorNotifBackgroundNegative: #fffbfa;
$colorNotifBackgroundWarning: #fffcf5;
$colorNotifBorderWarning: #fec84b;
$colorNotifBorderNegative: #fda29b;
$colorPendingText: #2659de;
$colorCancel: #ffbfbf;
$colorHoverRow: #e9f5f5;
$customGrey: #f2f4f7;
$lighterGrey: #eaecf0;
$darkerGray: #344054;
$lightestGrey: #eff8ff;
$lightBlue: #f9fafb;
$blue: #1570ef;
$primary700: #175cd3;
$primary900: #101828;
$grey600: #475467;

// Fixed values
$headerHeight: 71px;

// Border
$borderRadius: 20px;
$borderRadiusSmall: 12px;
$borderColor: rgba(255, 255, 255, 0.6);
$borderBlue: #a4e2fd;
$borderColorFaded: rgba(255, 255, 255, 0.3);
$borderComplete: $colorSuccessSecondary;
$borderIncomplete: #ec8686;
$borderPrimary: #d0d5dd;

//Box Shadows
$boxShadowColor: rgba(0, 117, 169, 0.1);
$cardBoxShadow: 0px 4px 15px $boxShadowColor;
$blueishBoxShadow: 0px 4px 15px rgba(0, 117, 169, 0.3);
$dropdownBoxShadow: rgba(196, 196, 196, 0.3);
$modalBoxShadow: 0px 4px 15px rgba(0, 117, 169, 0.15);
$redesignBoxShadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
  0px 1px 3px 0px rgba(16, 24, 40, 0.1);
// Z-index
$zIndexDefault: 1;
$zIndexFixedElements: 2;
$zIndexOverlay: 3;
$zIndexOverOverlay: 4;
$zIndexTop: 5;
$zIndexOverMenu: 9999;
