@import '../../../../styles/constants';

.select-loan__evaluation {
  margin: 24px 0;

  &__title {
    display: flex;
    flex-direction: column;
    color: $primary700;
    font-size: 0.875rem;
    font-weight: 600;
    width: 40%;

    span {
      color: $darkerGray;
      font-weight: 400;
      margin-top: 4px;
    }
  }

  &__arrow {
    border: 1px solid $borderPrimary;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    border-radius: 8px;
  }
}
