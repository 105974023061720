@import '../../../styles/constants';

.daily-funding {
  #info-icon {
    width: 20px;
    height: 20px;

    path {
      fill: $borderIncomplete;
    }
  }
}
