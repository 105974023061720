@import '../../../styles/constants.scss';

$toolboxWidth: 260px;

.toolbox {
  background: $white;
  border: 2px solid $colorCardBorder;
  box-sizing: border-box;
  box-shadow: $cardBoxShadow;
  border-radius: $borderRadius;
  position: relative;
  min-width: $toolboxWidth;
  max-width: $toolboxWidth;
  transition: width 1s;

  &--collapsed {
    transition: width 1s;
    max-width: 70px;
    min-width: 70px;
    overflow: hidden;
    .toolbox__items {
      padding: 20px 8px;
    }
  }
  &__items {
    padding: 20px 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__icon {
    min-width: 50px;
    &__loader {
      margin-right: 16.5px;
    }
    &--uw {
      min-width: 50px;
      svg path {
        fill: $colorButton;
      }
    }
  }

  &__item {
    margin: 20px 0;
    font-size: $fontSize;
    line-height: 1;
    display: flex;
    align-items: center;
    color: $colorLabel;
    flex-wrap: nowrap;
    &:hover {
      cursor: pointer;
    }

    &-text {
      min-width: 100px;
      margin-left: 10px;
    }
  }

  a:hover {
    color: $colorLabel;
  }
}

.toolbox-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  color: $black;
  margin-top: 18px;
}

.toolbox-navigation {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-right: 16px;
  padding: 18px 0;
  text-wrap: nowrap;

  &__item {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 6px;
    font-weight: 600;
    font-size: 0.75rem;

    &:hover {
      background-color: $customGrey;
      color: $black;
    }

    &--selected {
      background-color: $customGrey;
      color: $black;
    }
  }
}

@media only screen and (max-width: $desktop) {
  .toolbox {
    margin-left: 20px;
    position: fixed;
    bottom: 40px;
    right: 20px;
    z-index: $zIndexFixedElements;
    transition: width 1s;
    background: transparent;
    box-shadow: none;
    border: none;

    &__items {
      background: linear-gradient(4.07deg, #236582 3.32%, #80d0c7 96.68%);
      padding: 0;
      border-radius: $borderRadius;
      border: 0.5px solid $colorCardBorder;
      &--collapsed {
        display: none;
        pointer-events: none;
      }

      svg {
        width: 18px;
        height: 20px;
        path {
          fill: $white;
        }
        ellipse {
          stroke: $white;
        }
      }
    }

    &__item {
      margin: 15px 8px;
      color: $white;

      &-button {
        position: absolute;
        bottom: -37px;
        left: 77%;
        padding: 25px;
        background: linear-gradient(4.07deg, #236582 3.32%, #80d0c7 96.68%);
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.25);
        border-radius: 50px;
      }
    }

    &--collapsed {
      overflow: unset;
      .toolbox__items {
        padding: 0;
        margin: 0;
      }
      .toolbox__item {
        justify-content: center;
        margin: 15px 0;

        &-button {
          left: -9px;
        }

        &-text {
          display: none;
        }
      }
    }

    &__icon {
      &__loader {
        width: 50px !important;
        height: 20px !important;
        background: transparent;
        margin-right: 0;
        .loader__svg-container {
          left: 70%;
        }
      }

      &-stroke {
        min-width: 50px;
        svg {
          path {
            fill: transparent;
            stroke: $white;
          }
        }
      }

      &-fill {
        svg {
          path {
            fill: $white;
            stroke: transparent;
          }
        }
      }
    }
  }
}
