@import '../../styles/constants.scss';

$selectedBackground: #f2f9fc;

.dti {
  &__shareholder-percentage {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  &__card-title {
    display: flex;
    justify-content: space-between;
  }
  .input__wrapper {
    margin-top: 0;
  }
  .error-wrapper {
    display: none;
  }

  &__debt {
    &-item {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;
      position: relative;
    }
  }

  &__tooltip {
    position: absolute;
    right: 10px;
    svg {
      cursor: pointer;
      width: 20px;
      height: 20px;
    }
  }
}
