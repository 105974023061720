.funding-history {
  .afp__header {
    flex-wrap: wrap;
  }
  .afp-funding {
    width: 100%;
    margin-top: 48px;
  }
  .calendar {
    margin-left: auto;
  }
}
