@import '../../styles/constants';

.roles-management {
  padding: 0px;
}

.add-new-role {
  width: 100%;
  display: grid;
  grid-template-columns: 0.49fr 0.49fr;
  grid-template-rows: auto;
  justify-content: space-between;

  &__checkbox {
    margin-top: 20px;
    > div > span {
      color: $colorLabel;
      font-size: $fontSize;
      font-weight: 500;
    }
  }

  &__right-col {
    padding-top: 20px;
  }

  &__select-permission {
    margin-top: 15px;
  }

  &__select-permission-text {
    font-weight: bold;
    color: #236582;
    margin-bottom: 10px;
  }
}

.roles-card-container {
  padding: 0px;
}

.roles-card-content {
  display: grid;
  grid-template-columns: 0.98fr 0.01fr 0.01fr;
  justify-content: space-between;
  padding: 17px 0 3px 10px;

  &__name {
    font-size: 1.125rem;
    color: $colorLabel;
    font-weight: 600;
  }

  &__edit {
    cursor: pointer;
    width: 27px;
    height: 27px;
  }

  &__delete {
    cursor: pointer;
    width: 27px;
    height: 27px;
    circle {
      fill: #e35548;
    }
  }
}
