@import '../../styles/constants.scss';

.json-wrapper {
  padding: 8x;
  background-color: $white;
  border-radius: 20px;
}

.json-viewer {
  &__value {
    display: inline-flex;
    margin-left: 8px;
    color: $black;

    &-edit_icon {
      margin-left: 8px;
      height: 26px;
      min-width: 26px;
      cursor: pointer;
    }
  }
}
