@import '../../styles/constants.scss';

.set-password {
  &__subtitle {
    font-size: 1.125rem;
    font-weight: bold;
    margin-bottom: 24px;
    color: $black;
    margin-top: 12px;
  }

  &__unordered-list {
    color: $black;
    padding-left: 20px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
  }

  &__subtext {
    color: $darkerGray;
    font-size: 16px;
    font-weight: 400;
  }

  &__ordered-list {
    padding-left: 20px;
    color: $black;
  }

  &__subitem {
    font-weight: bold;
  }
}
