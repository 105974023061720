@import '../../../../../../styles/constants.scss';

.salesRepCard,
.borrowerCard {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
}

@media only screen and (max-width: $tablet) {
  .generalCard,
  .borrowerCard,
  .salesRepCard {
    grid-template-columns: 1fr;
  }
}
