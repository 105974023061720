@import '../../styles/constants.scss';

$infoColor: #344054;
$borderPrimary: #d0d5dd;

.loan-calculator {
  &__title {
    font-weight: 700;
    font-size: 0.875rem;
    color: $black;
    margin: 0 0 20px;
    display: flex;
    align-items: center;
  }

  &__monthly {
    &-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 50px;
      margin-bottom: 40px;
      font-size: 0.875rem;
      font-weight: 500;
      color: $grey600;
    }

    &-amount {
      font-weight: 600;
      font-size: 2.25rem;
      color: $black;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    padding: 24px;
    background: $lightBlue;
    border-right: 1px solid $borderPrimary;
    width: 368px;
  }

  &__results {
    padding: 24px;
    width: 463px;
  }

  &__flex {
    display: flex;

    &--margin {
      margin-top: 26px;
    }
  }

  &__disclaimer {
    font-size: 0.875rem;
    margin-top: 8px;
    color: $grey600;
  }

  &__table {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    grid-row-gap: 16px;
    grid-column-gap: 16px;
    font-size: 0.875rem;

    &__header {
      font-size: 0.75rem;
      color: $grey600;
      margin-bottom: 5px;
      text-align: center;
      font-weight: 500;
    }

    &__header--left {
      text-align: left;
    }

    &__heading {
      color: $colorLabel;
      font-weight: 400;
    }

    &__value {
      font-weight: 600;
      text-align: center;
      color: #101828;
    }

    &__line {
      grid-column: 1 / -1;
      height: 1px;
      background: $lighterGrey;
    }

    &--small {
      grid-template-columns: 3fr 2fr 2fr;
    }

    &--mobile {
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 0;
      .loan-calculator__table__header {
        margin-bottom: 0;
      }
    }

    &__mobile-row {
      margin-bottom: 22px;
      .loan-calculator__table__value {
        text-align: left;
      }
    }
  }

  &__x {
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;
  }
}

@media only screen and (max-width: $desktop) {
  .loan-calculator {
    &__flex {
      flex-direction: column;

      &--margin {
        margin-top: 0;
      }
    }

    &__wrapper {
      padding: 24px;
      background-color: $lightBlue;
      width: 100%;
      border-right: none;
      border-bottom: 1px solid $borderPrimary;
    }

    &__results {
      width: 100%;
      min-height: 270px;
    }

    &__monthly {
      &-wrapper {
        margin-bottom: 20px;
        margin-top: 0;
      }
      &-amount {
        font-size: 2rem;
      }
    }

    &__disclaimer {
      margin-top: 0;
    }

    &__dropdown {
      width: 100%;
    }
  }
}
