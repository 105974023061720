@import './constants';

.loan-form {
  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 25px 24px;
    align-items: end;

    .input__inner {
      margin-bottom: 0;
    }
  }

  &__input {
    margin-top: 20px;
    .input[type='password'] {
      padding-left: 0;
    }
    .input__inner {
      margin-top: auto;
    }
    .input__label {
      margin-bottom: 11px;
    }
  }

  &__input-row {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin: 30px 30px 0;
  }

  &__checkbox {
    margin: 30px 0 40px 40px;
    &-main {
      margin-top: 20px;
      margin-left: 30px;
      &--coborrower {
        .checkbox {
          &__label {
            font-size: 1.16rem;
            color: $colorLabel;
            font-weight: 600;
          }
        }
      }
    }
  }
  &__title {
    font-weight: bold;
    font-size: 1.25rem;
    line-height: 1;
    margin: 40px 0 6px 40px;

    color: $colorLabel;
  }
  &__text {
    font-style: italic;
    color: $grey;
    margin-left: 40px;
    margin-top: 30px;
    max-width: 60%;
  }

  &__card {
    max-width: 1200px;
    margin: 0 auto 30px auto;
  }
  &__wrapper {
    padding: 30px 0;
  }
}

.grid-column-start {
  grid-column-start: 1;
}

@media only screen and (max-width: $desktop) {
  .loan-form {
    &__grid {
      grid-template-columns: 1fr;
      grid-gap: 20px;
    }

    &__checkbox-main--coborrower .checkbox__label {
      font-size: 0.75rem;
    }

    &__input {
      margin: 20px 0 0;
      &-row {
        flex-direction: column;
      }
    }

    &__text {
      margin-left: 0;
    }

    &__checkbox {
      margin-left: 0;

      &-main {
        margin-left: 10px;
      }
    }

    &__mla .input__label {
      margin-bottom: 27px;
    }
  }
}
