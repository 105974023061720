.dealer-settings {
  .afp__expanded__title {
    > p {
      margin: 0;
    }

    > span {
      font-size: 1rem;
      font-weight: normal;
    }
  }
  .afp__grid-inputs {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 30px;
  }
}
