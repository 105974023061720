@import '../../../styles/constants';
@import '../../../styles/mixins';

.pto-submission {
  &__date {
    position: relative;
    height: 400px;
    &--disabled {
      @include disabled();
    }

    .react-datepicker-popper {
      inset: 50px 0 0 50% !important;
      transform: translateX(-50%) !important;
      width: 307px;
    }

    .react-datepicker {
      border: 0;
      box-shadow: none;
      width: 100% !important;
    }

    .react-datepicker__header__dropdown {
      display: none;
    }
  }

  &__title {
    color: $darkerGray;
    font-weight: 600;
    text-align: center;
  }

  &__content {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 32px;
  }
}

@media only screen and (max-width: $laptop) {
  .pto-submission__content {
    grid-template-columns: 3fr 2fr;
  }
}

@media only screen and (max-width: $tablet) {
  .pto-submission__content {
    grid-template-columns: 1fr;
  }
}
