@import './constants.scss';

.admin-template {
  padding: 0;

  &__textfields {
    display: flex;
    max-width: 686px;
  }

  &__decisions {
    margin-top: 32px;
    width: 100%;

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 33px;
    }

    &-title {
      font-size: 30px;
      font-weight: bold;
      color: $black;
    }
  }

  &__copy-list {
    max-height: 500px;
    overflow: scroll;
    &-buttons {
      display: flex;
      margin-top: 15px;
    }
  }

  .textarea {
    min-height: 89px;
  }
}

.firebase-form {
  &__fields {
    display: flex;
    margin-top: 24px;
  }
  &__title {
    font-weight: 700;
  }

  &__wrapper {
    margin: 30px;
    padding: 24px;
    border: 1px solid $customGrey;
    border-radius: 12px;
    background: $white;
    margin-bottom: 32px;
    box-shadow: $redesignBoxShadow;
  }
}

.admin-page {
  &__header {
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 600;
    color: $black;
    margin-bottom: 4px;
    &-wrapper {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $lighterGrey;
      padding-bottom: 20px;
      align-items: center;
      margin-bottom: 40px;
    }
  }
}

@media only screen and (max-width: $desktop) {
  .admin-page {
    &__content {
      padding: 0 20px;
    }
    padding: 32px 32px 48px;
  }
}

@media only screen and (max-width: $tablet) {
  .admin-page {
    padding: 32px 16px;

    &__content {
      padding: 0 5px;
    }
    &__header {
      &-wrapper {
        display: block;
      }
    }
  }
  .admin-template {
    &__copy-list {
      max-height: 400px;
    }
    &__decisions {
      margin-top: 16px;
      &-header {
        flex-wrap: wrap;
      }
    }
  }
}
