@import '../../styles/constants';

$tooltipGrey: #a3b4bb;
$secondaryBoxShadowColor: rgba(196, 196, 196, 0.5);

.tab-selector {
  width: fit-content;
  height: 61px;
  box-sizing: border-box;
  border-radius: 50px;
  border: 1px solid $colorCardBorder;
  box-shadow: $cardBoxShadow;
  background: #fcfcff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  pointer-events: all;

  &__tab {
    font-size: $fontSizeSecondary;
    padding: 12px 20px;
    cursor: pointer;
    font-weight: 500;
    line-height: 1.2;
    color: $colorLabel;
    text-align: center;

    &--selected {
      font-weight: bold;
      color: $colorSuccessSecondary;
      border: 1px solid $colorSuccessSecondary;
      box-shadow: $cardBoxShadow;
      border-radius: 20px;
      box-sizing: border-box;
    }
  }

  &__tooltip {
    font-size: 0.625rem;
    color: $tooltipGrey;
    font-weight: normal;
    padding-top: 5px;
  }
}

.tab-selector--secondary {
  border-radius: 15px;
  background: transparent;
  box-shadow: none;
  border: none;
  height: auto;
  padding: 0;

  .tab-selector__tab {
    width: 154px;
    height: 55px;
    background: #f9f9f9;
    color: $tooltipGrey;
    border-radius: 15px;
    padding: 8px 37px;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.25;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    margin-right: -1px;
    position: relative;
    box-shadow: -3px -4px 15px $secondaryBoxShadowColor;

    // Squares
    &:before,
    &:after {
      background: #f9f9f9;
      width: 10px;
      height: 10px;
      position: absolute;
      bottom: 0;
      content: '';
    }
    &:before {
      left: 0;
    }
    &:after {
      right: 0;
    }

    &__inner {
      // Circles
      &:before,
      &:after {
        position: absolute;
        bottom: 0;
        content: none; // Only show for active tab
        width: 20px;
        height: 20px;

        /* Circles are circular */
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        background: #f9f9f9;
        //background-color: black;
        z-index: 2;
      }
      &:before {
        left: -20px;
      }
      &:after {
        right: -10px;
      }
    }
  }

  > :first-child {
    border-radius: 15px 15px 0 0;
  }
  > :last-child {
    border-bottom-right-radius: 0;
  }

  // Active tab
  .tab-selector__tab--selected {
    color: $colorLabel;
    background: $white;
    border: none;
    box-shadow: none;
    border-radius: 15px 15px 0 0;
    z-index: 2;

    // Squares for active tab
    &:before,
    &:after {
      z-index: 1;
      background: $white;
    }
    &:before {
      left: -10px;
    }
    &:after {
      right: -10px;
    }

    // Circles for active tab
    .tab-selector__tab__inner {
      &:before,
      &:after {
        background: #f9f9f9;
        content: '';
      }
      &:after {
        right: -20px;
      }
    }
  }

  // For the first tab hide :before elements
  .tab-selector__tab:first-child {
    &:before {
      display: none;
    }
    .tab-selector__tab__inner:before {
      display: none;
    }
  }

  // For the last tab blend it with the background color of the site
  .tab-selector__tab:last-child {
    &:after {
      right: -10px;
    }
    .tab-selector__tab__inner:after {
      content: '';
      background: $colorBackground;
      right: -20px;
    }
  }
}

@media only screen and (max-width: $desktop) {
  .tab-selector {
    margin-bottom: 24px;
  }
}
@media only screen and (max-width: $tablet) {
  .tab-selector__tooltip {
    display: none;
  }
}
