@import '../../styles/constants';

.afp {
  &__header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 32px;

    &__buttons {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      margin-top: -10px;

      > button {
        margin-top: 10px;
      }
    }
  }

  &__modal {
    .modal__container {
      max-width: 75%;
      padding: 30px;
      text-align: left;

      .modal__container-content {
        max-height: 65vh;
      }
    }

    .modal__title {
      font-size: 1.5rem;
      font-weight: bold;
      text-align: left;
      color: $checkboxLabel;
    }

    .modal__close {
      top: 40px;
      right: 40px;
      font-size: 1.125rem;
    }

    .modal__container-buttons {
      justify-content: flex-start;
      flex-direction: row-reverse;

      .button:last-child {
        margin-right: 20px;
      }

      .button__text {
        padding: 8px 18px;
      }
    }
  }

  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: $zIndexOverOverlay;
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: $zIndexOverlay;
  }

  &__expanded {
    &__header {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 8px 24px 0;
      box-sizing: border-box;
      margin-bottom: 28px;

      button:first-of-type {
        margin-left: auto;
        margin-right: 10px;
      }
    }

    &__title {
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.5;

      p {
        margin: 0;
      }
    }
  }

  &__grid-inputs {
    padding: 0 24px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 85px;
    grid-row-gap: 10px;

    .input__label,
    .input {
      font-size: 0.875rem;
    }

    .error-wrapper {
      display: none;
    }
  }

  .search-module {
    width: 30%;
    margin: 0;

    &__search {
      width: 100%;
      margin: 0;

      .input__wrapper {
        margin: 0 20px 0 0;
      }
    }
  }

  .admin-page__header-wrapper {
    margin-bottom: 32px;
  }
}
