@import '../../../../styles/constants.scss';

.label {
  color: $colorLabel;
  white-space: nowrap;
  margin-right: 12px;

  &:after {
    content: ':';
  }
}

.loanProductValueWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.labelWrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.cardsActions {
  display: flex;
  align-items: center;
}

.actionItem {
  color: $primary700;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.5;
  margin-left: 16px;
  &:first-child {
    margin-left: 0;
  }
}

.cardButtons {
  display: flex;
  margin: 0;
}

.cardsWrapper {
  font-size: 12px;
  font-weight: 500;
}

@media only screen and (max-width: $tablet) {
  .cardsWrapper {
    grid-template-columns: 1fr;
  }
}
