@import '../../../../styles/constants.scss';

.income-estimator {
  &__title {
    font-size: 0.875em;
    font-weight: 700;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    color: $black;
  }

  &__item {
    font-size: 0.875rem;
    color: $colorLabel;
    margin-bottom: 8px;

    &-wrapper {
      display: flex;
      flex-direction: column;
      width: 240px;
    }
  }

  &__paper {
    padding: 24px;
    border: 1px solid $customGrey;
    border-radius: 12px;
    background: $white;
    margin-bottom: 12px;
    box-shadow: $redesignBoxShadow;
  }

  &__fields {
    display: flex;
    flex-direction: row;
    margin-top: 16px;
    justify-content: space-between;
    &:first-child {
      margin-top: 0;
    }
    &--no-space-between {
      justify-content: start;
    }
  }

  &__result {
    font-size: 0.875rem;
    color: $black;
    font-weight: 500;
    max-width: 140px;
  }
}

@media only screen and (max-width: $desktop) {
  .income-estimator {
    &__fields {
      flex-direction: column;
      margin-top: 0;
    }
    &__title {
      display: none;
    }
    &__item {
      margin-bottom: 6px;
      &-wrapper {
        margin-top: 24px;
      }
    }
  }
}
