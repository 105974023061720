@import '../../../../styles/constants.scss';

.actions {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__items {
    display: flex;
    flex-direction: column;
    width: 200px;
    text-align: center;
    margin-bottom: 14px;
    margin-top: 10px;
    &-name {
      color: $black;
      margin-bottom: 12px;
      font-weight: 700;
      text-align: center;
      margin-right: 16px;
      width: 100%;
    }
    &-wrapper {
      display: flex;
      align-items: center;
    }
  }
}
