@import '../../../../styles/constants';

.history-ecg {
  &__title {
    font-weight: 700;
    font-size: 0.875rem;
    color: $black;
    margin-top: 20px;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }
}

@media only screen and (max-width: $desktop) {
  .history-ecg {
    &__title {
      display: none;
    }
    &__wrapper {
      margin-top: 20px;
    }
  }
}
