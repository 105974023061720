@import '../../styles/constants.scss';

@media only screen and (min-width: $desktop) {
  .collapsible {
    border: 1px solid $lighterGrey;
    border-radius: $borderRadiusSmall;
    overflow: hidden;
  }
}

@media only screen and (max-width: $desktop) {
  .collapsible {
    &__content {
      &-flex {
        display: flex;
        flex-direction: column;
        width: 100%;
      }

      &-row {
        margin: 6px 16px;
        font-size: 0.875rem;
      }

      &-item {
        padding: 12px 16px;
        border-bottom: 1px solid $lighterGrey;
        color: $black;
        font-size: 0.75rem;

        &--bold {
          margin-left: 12px;
          font-weight: 500;
          font-size: 0.875rem;
        }
      }
    }
  }
}
