@import '../../styles/constants';

$selectedBackground: #f2f9fc;
$itemBackground: #b6e9ff;

.loan-document {
  &__paper {
    padding: 24px;
    border: 1px solid $customGrey;
    border-radius: 12px;
    background: $white;
    margin-bottom: 32px;
    box-shadow: $redesignBoxShadow;
    display: flex;
    flex-direction: column;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
  &__info {
    font-size: 1.25rem;
    font-weight: 600;
  }
  &__envelope {
    display: flex;
    height: 70px;
    padding: 16px 24px;
    border-bottom: 1px solid $lighterGrey;
    margin-bottom: 10px;
    align-items: center;
    font-weight: bold;
    color: $black;
    &:hover {
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: $desktop) {
  .loan-document {
    &__paper {
      padding: 0;
      border: none;
      background: none;
      box-shadow: none;
    }

    &__header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 16px;
    }
  }
}
