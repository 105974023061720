@import '../../styles/constants';

.radio {
  cursor: pointer;

  &__label {
    cursor: pointer;
    position: relative;
    padding-left: 25px;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 15px;
      height: 15px;
      border: 2px solid $checkboxLabel;
      box-sizing: border-box;
      border-radius: 100%;
    }
  }

  &-wrapper {
    input[type='radio'] {
      display: none;
    }
    input[type='radio']:checked + .radio__label:after {
      content: '';
      position: absolute;
      left: 3px;
      top: 50%;
      transform: translateY(-50%);
      width: 9px;
      height: 9px;
      border-radius: 100%;
      background: $colorButton;
    }
  }
}
