@import '../../../../styles/constants.scss';

.uw-hub-page__uw-approval-buttons {
  margin-right: 24px;
  display: flex;
  flex-direction: row;

  &-portal {
    margin-right: 12px;
  }

  &-uw {
    position: relative;
  }

  &-dialog {
    z-index: 100;
    position: absolute;
    width: 100%;
    margin-top: 6px;
    background-color: white;
    border-radius: 8px;
    border: 1px solid $colorCardBorder;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

    > button {
      width: 100%;

      &:hover {
        background-color: $lighterGrey;
      }
    }
  }
}

@media only screen and (max-width: $tablet) {
  .uw-hub-page__uw-approval-buttons {
    margin-right: 0;

    &-portal {
      margin-right: 0;

      > button {
        padding: 0;
      }
    }

    &-uw {
      width: 100%;
      margin-bottom: 16px;

      > button {
        width: 100%;
      }
    }
  }
}
