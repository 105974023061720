@import '../../styles/constants';
$progressBarPadding: 100px;
$bubbleSize: 67px;

.flow {
  &__progress {
    padding: 78px 0 78px $progressBarPadding;
    position: relative;
    box-sizing: border-box;
  }
}
