@import '../../../../styles/constants';

.afp-funding {
  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: 100%;
    font-size: 0.75rem;
    font-weight: 500;
    align-items: center;
    &--row {
      font-size: 0.875rem;
    }
  }
}
