@import '../../styles/constants.scss';
@import '../../styles/mixins.scss';

.search-bar {
  position: relative;
  &--disabled {
    @include disabled();
  }

  &__selected {
    border: none;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 12px;
    margin-top: 12px;

    &-wrapper {
      background: $white;
      border: 0.5px solid #d0d5dd;
      border-radius: 6px;
      padding: 5px 10px;
      margin-right: 10px;
      margin-bottom: 5px;
      height: 28px;
      z-index: 2;
      display: flex;
      align-items: center;
      cursor: pointer;
      box-sizing: border-box;
    }

    &-item {
      font-size: 0.813rem;
      color: #344054;
      font-weight: 500;
    }

    &-remove {
      margin-left: 4px;
      font-weight: 800;
      font-size: 0.813rem;
      color: #98a2b3;
      line-height: 10px;
    }
  }

  &__clear {
    height: 28px;
    .button__text {
      font-size: 0.75rem;
      padding: 0 20px;
    }
  }
}

.searchbar-options {
  margin-top: 10px;
  border-radius: 6px;
  background-color: $white;
  box-shadow: 0px 4px 15px rgb(210 210 210 / 30%);
  min-width: 230px;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  z-index: $zIndexOverlay;
  position: absolute;
  padding: 12px 0 0;

  &__item {
    margin-top: 5px;
    padding: 5px 25px;
    cursor: pointer;
    font-size: 1rem;
    color: $checkboxLabel;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 10px;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
}

@media only screen and (max-width: $desktop) {
  .searchbar-options {
    &__item {
      font-size: 0.75rem;
    }
  }
}
