@import '../../../styles/mixins';

.not-opted-in {
  @include styledScrollbar();

  .modal {
    &__close {
      display: none;
    }

    &__container {
      width: 800px;
      max-width: 90%;
      height: auto;
    }
    &__container-content {
      margin-bottom: 0;
    }
  }

  .card {
    border: 0;
    box-shadow: none;

    &__wrapper {
      display: none;
    }
    &__content {
      border: 0;
      padding: 10px 20px 0;
    }
    .loan-form__input-row {
      margin: 20px 0;
    }
  }

  .ach-form__text {
    max-height: 140px;
    overflow: auto;
  }
}
