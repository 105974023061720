@import '../../styles/constants';

.outage-message {
  &__subtitle {
    color: $black;
    font-size: 1.125rem;
    font-weight: 600;
    text-align: left;
    margin-bottom: 24px;
  }

  &__paper {
    padding: 24px;
    border: 1px solid $customGrey;
    border-radius: 12px;
    background: $white;
    margin-bottom: 32px;
    box-shadow: $redesignBoxShadow;

    display: flex;
    flex-direction: column;
  }

  &__fields {
    display: flex;

    &-wrapper {
      display: flex;
      flex-direction: column;
    }
  }

  &__margin {
    width: 100%;
  }

  &__buttons {
    width: 100%;
    text-align: end;
    margin-top: 24px;
  }

  &__table {
    margin-top: 24px;
  }
}

@media only screen and (max-width: $desktop) {
  .outage-message {
    &__fields {
      flex-direction: column;
    }
    &__margin {
      margin-top: 16px;
    }

    &__buttons {
      text-align: center;
      display: flex;
      flex-direction: column;
    }
  }
}
