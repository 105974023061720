@import "../../styles/constants";

.modal--secondary .modal {
  &__container {
    text-align: left;
    padding: 30px;
    height: 80%;
    overflow: hidden;

    &-content {
      margin-top: 0;
      overflow: auto;
      height: inherit;
    }
  }
  &__close {
    top: 30px;
    right: 30px;
  }
  &__title {
    font-size: 1.285rem;
    color: $colorLabel;
    font-weight: bold;
    margin-bottom: 30px;
    margin-top: 0;
  }
}
