@import '../../../../styles/constants.scss';

.notes {
  &__items {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    padding-top: 10px;
    max-height: 300px;
    overflow: auto;
  }

  &__item {
    padding: 10px 10px 20px;
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;

    &-header {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      &-content {
        display: flex;
        align-items: center;
        justify-content: center;

        > div {
          display: flex;
          flex-direction: column;
          margin-left: 12px;
        }
      }
    }

    &-name {
      color: $colorLabel;
      font-size: 0.875rem;
    }

    &-date {
      font-size: 0.75rem;
      color: $grey;
    }

    &-text {
      margin-top: 12px;
      margin-left: 58px;
      font-size: $fontSizeSecondary;
      color: $checkboxLabel;
      p,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin: 0;
      }
    }

    &-wrapper {
      display: flex;
      align-items: flex-start;
    }

    &-attachment {
      background: $white;
      border: 0.5px solid $colorUpload;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 10px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      margin-top: 24px;
      font-size: 0.875rem;
      line-height: 17px;
    }

    &-attachment-name {
      display: flex;
      align-items: center;

      :first-child {
        margin-right: 20px;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}

@media only screen and (max-width: $desktop) {
  .notes__items {
    margin-top: 0;
  }
}

@media only screen and (max-width: $tablet) {
  .notes__item-text {
    margin-left: 0;
  }
}
