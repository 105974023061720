@import '../../../styles/constants.scss';
@import '../../../styles/form.scss';
@import '../../../styles/mixins.scss';

.applicant-info {
  width: 100%;

  &__form-wrapper {
    display: grid;
    row-gap: 12px;
    grid-template-columns: auto;
  }

  &__loan-details {
    display: grid;
    row-gap: 24px;

    .special-fields {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 24px;
      align-items: end;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__trash {
    padding-right: 20px;

    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    circle {
      fill: red;
    }
    &:hover {
      cursor: pointer;
    }
  }

  .service-address {
    display: grid;
    grid-template-rows: 1fr;
    grid-row-gap: 24px;
    margin-top: 24px;
  }

  .loan-form {
    &__grid {
      grid-template-columns: 1fr 1fr 1fr;
    }

    &__additional {
      padding: 10px 10px 30px;
      font-size: 0.875rem;
      &-text {
        font-weight: bold;
      }
    }
    &__custom-button {
      display: flex;
      justify-content: flex-end;
    }
  }

  .button__clipboard {
    position: absolute;
    top: -6px;
    right: 20px;
    cursor: pointer !important;
    pointer-events: all !important;

    .button__text {
      padding: 6px 10px;
      display: flex;
      align-items: center;
      font-size: 0.875rem;
    }
    svg {
      margin-right: 5px;
    }
  }

  &__modal {
    &-text {
      max-height: 343px;
      overflow: auto;
      text-align: start;
      overflow-x: hidden; /* Hide horizontal scrollbar */
      margin-bottom: 20px;
      padding-right: 30px;
    }
    &-title {
      color: $black;
      font-weight: bold;
      margin-bottom: 13px;
      text-align: start;
    }
    @include styledScrollbar();
  }

  &__select-loan .dropdown__label {
    margin-bottom: 25px;
  }

  &__dropdown {
    box-sizing: border-box;
    width: 100%;
    padding: 0 30px;
  }

  &__input {
    margin: 20px 40px 10px 40px;
  }

  .places-input {
    margin-top: 20px;
    width: 100%;
  }

  &__button {
    margin-right: 20px;
    margin-top: 35px;
    text-align: center;
  }

  .input {
    &[type='number']:not([class*='input--no-padding']) {
      padding-left: 17px;
    }
  }

  .input__label,
  .dropdown__label {
    color: $colorButton;
  }

  &__form {
    display: flex;
    flex-direction: row;

    &-address {
      width: auto;
      border-radius: 0 15px 15px 0;
      margin-bottom: 40px;
      box-sizing: border-box;

      &--product {
        background: $colorLabel;
        min-width: 266px;
        flex: 33%;
        padding: 20px 30px;
      }

      .applicant-info__input {
        margin: 20px 0 0 0;
      }
      .dropdown svg path {
        stroke: $white;
      }

      &--mobile {
        display: none;
      }
    }
    &-general {
      margin-top: 40px;
      margin-bottom: 20px;
      position: relative;
      display: grid;
      row-gap: 12px;
      grid-template-columns: auto;
    }

    &-title {
      font-weight: bold;
      font-size: 1.125rem;
      line-height: 1;
      color: $black;

      &--address {
        font-weight: bold;
        font-size: 1.25rem;
        line-height: 1;
        margin-left: 40px;
        color: $white;
      }
    }

    &-subtitle {
      font-weight: bold;
      font-size: 0.75rem;
      margin: 20px 0 20px 40px;
      line-height: 1;
      &--light {
        font-weight: 400;
        margin: 10px 0 0 0;
      }
      &--med {
        font-weight: 500;
        margin: 10px 0 30px;
        cursor: pointer;
      }
    }
  }
}

.scan-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding-top: 0px;

  > svg {
    cursor: pointer;
  }
}

@media only screen and (max-width: $desktop) {
  .loan-form {
    &__input {
      margin: 20px 0 0;
      &-row {
        flex-direction: column;
      }
    }
    &__text {
      margin-left: 0;
    }
    &__checkbox {
      margin-left: 0;
      &-main {
        margin-left: 0;
      }
    }

    &__mla .input__label {
      margin-bottom: 27px;
    }
  }
  .applicant-info {
    &__input {
      margin: 15px 0 0;
    }
    .button__clipboard {
      position: absolute;
      top: 10px;
      right: 20px;
      pointer-events: all;
    }
    &__dropdown {
      padding: 20px;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      .dropdown {
        &__label {
          color: $colorScroll;
        }
        &__selected {
          color: $white;
          font-size: 12px;
        }
      }
    }

    &--even,
    &--odd {
      margin: 30px 0;
    }
    .loan-form__grid {
      grid-template-columns: 1fr 1fr;
    }
    &__form {
      &-subtitle {
        display: none;
      }
      &-title {
        margin: 0;
        flex-wrap: wrap;
        gap: 12px;
        &--address {
          display: none;
        }
      }
      &-general {
        margin-top: 0;
      }
      &-address {
        display: none;
        &--mobile {
          padding: 20px;
          margin-bottom: 25px;
          display: flex;
          flex-direction: column;
          border-radius: 0 0 15px 15px;
          .places-input {
            margin: 20px 0 0;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $tablet) {
  .applicant-info {
    .loan-form {
      &__grid {
        grid-template-columns: 1fr;
      }
    }

    &__loan-details {
      .special-fields {
        grid-template-columns: 1fr;
      }
    }
  }
}
