@import '../../../styles/constants';

.roles-settings {
  &__fields {
    margin-top: 12px;
    display: flex;
    width: 100%;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    margin-top: 24px;
  }

  &__section {
    display: flex;
    justify-content: space-between;
  }
  &__action {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    margin-top: 12px;

    &-wrapper {
      display: flex;
      flex-direction: column;
      margin-left: 12px;
    }
  }
  &__subheader {
    color: $black;
    font-size: 1.125rem;
    font-weight: 600;

    &-wrapper {
      display: flex;
      flex-direction: column;
      margin-bottom: 12px;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &__item {
    width: 100%;
    color: $black;
    font-size: 0.9rem;
    font-weight: 700;
    padding: 24px;
    margin-top: 16px;
    border-radius: 12px;
    border: 1px solid $customGrey;
    background: $white;
    box-shadow: $redesignBoxShadow;
  }
}

@media only screen and (max-width: $desktop) {
  .roles-settings {
    &__fields {
      flex-direction: column;
    }
  }
}
