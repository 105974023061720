.loan-product__settings {
  flex-direction: row;
  margin: 0 auto;
  padding-bottom: 20px;

  .applicant-info__form-general {
    margin: 0;
    border-radius: 10px 0 0 10px;
  }

  .applicant-info__form-address {
    border-radius: 0 10px 10px 0;
  }
}
