@import '../../styles/constants';

$customGrey: #f2f4f7;

.history {
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 0;
    margin-bottom: 32px;
  }
  &__title {
    font-weight: bold;
    font-size: 2rem;
  }
  &__paper {
    padding: 24px 24px 0;
    border: 1px solid $customGrey;
    border-radius: 12px;
    background: $white;
  }
}

@media screen and (max-width: $tablet) {
  .history {
    &__header {
      margin-bottom: 16px;
      padding-top: 16px;
    }

    &__title {
      font-size: 1.625rem;
    }
    &__paper {
      padding: 12px 12px 12px 6px;
    }
  }
}
