@import '../../../../../../styles/constants.scss';

.salesRepCard,
.borrowerCard {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
}

.borrowerCard {
  &:first-child {
    margin-bottom: 24px;
  }
}

@media only screen and (max-width: $tablet) {
  .borrowerCard,
  .salesRepCard {
    grid-template-columns: 1fr;
  }

  .borrowerCard {
    margin-bottom: 24px;
  }
}
