@import '../../../../styles/constants.scss';

.notes {
  &__title {
    font-weight: 700;
    font-size: 0.875rem;
    color: $black;
    margin-bottom: 20px;
    margin-top: 20px;
    display: flex;
    align-items: center;
  }
}

@media only screen and (max-width: $desktop) {
  .notes {
    min-width: auto;

    &__title {
      display: none;
    }
  }
}

@media only screen and (max-width: $desktop) {
  .notes {
    margin-top: 16px;
  }
}
