@import '../../../../styles/constants.scss';

.property-title {
  margin: 15px 0 0 0;
  border-radius: 25px;

  &__title {
    font-weight: 700;
    font-size: 0.875rem;
    color: $black;
    margin-bottom: 20px;
    margin-top: 20px;
    display: flex;
    align-items: center;
  }

  &__item {
    width: 100%;
    color: $black;
    font-size: 0.9rem;
    font-weight: 700;
    padding: 24px;
    cursor: pointer;

    border-radius: 12px;
    border: 1px solid $customGrey;
    background: $white;
    box-shadow: $redesignBoxShadow;
  }

  &__wrap {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 5px;
    margin-top: 8px;
    &--3 {
      grid-template-columns: repeat(3, 1fr);
    }
    &--1 {
      grid-template-columns: 1fr;
    }
  }

  &__child {
    margin-top: 20px;
    font-weight: bold;

    &--regular {
      font-weight: normal;
    }
  }

  &__box {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }
  &--bordered {
    border: 0.5px solid #e2e2e2;
  }

  &__name {
    display: flex;
    justify-content: space-between;
    color: $black;
    font-size: 0.9rem;
    font-weight: bold;
    align-items: center;
    &-child {
      color: $colorLabel;
      font-size: inherit;
      font-weight: 500;
    }
  }

  &__children {
    display: flex;
    flex-direction: column;
    padding: 10px 10px 0 0;

    &--full {
      display: inherit;
      padding: inherit;
      width: 100%;
    }
  }

  &--subtitle {
    width: 100%;
  }
  &__wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    span {
      font-weight: 500;
      margin-top: 8px;
    }
  }
}

.property-title-modal {
  height: 60vh;
  min-height: 300px;
}

@media screen and (max-width: $tablet) {
  .property-title {
    &__wrap {
      grid-template-columns: 1fr;
    }
  }
}
