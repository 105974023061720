@import '../../styles/constants.scss';

.modal {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  z-index: $zIndexOverlay;
  align-items: center;

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $colorScroll;
  }

  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
    font-weight: 800;
    cursor: pointer;
    z-index: $zIndexDefault;
  }

  &__title {
    font-weight: 600;
    font-size: $fontSize;
    line-height: 1;
    color: #236582;
    margin-bottom: 18px;
  }

  &__container {
    text-align: center;
    width: 100%;
    max-width: 450px;
    margin: 0 auto;
    position: relative;
    background: $white;
    box-shadow: $modalBoxShadow;
    border-radius: 15px;
    padding: 20px;

    &--no-padding {
      padding: 0;
      min-width: 724px;

      .modal__close {
        color: $white;
        z-index: 1;
      }
    }

    &-content {
      margin-top: 30px;
      margin-bottom: 30px;
      max-height: 80vh;
      overflow-y: auto;
      padding: 8px 0;

      &--no-margin {
        margin: 0;
      }
    }

    &--no-width {
      max-width: 1200px;
      max-height: 80%;
      overflow: scroll;
      padding: 0;
      width: auto;
      text-align: left;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &-buttons {
      display: flex;
      justify-content: space-evenly;
      &--end {
        margin-top: 15px;
        justify-content: end;
      }
    }
  }
}

@media only screen and (max-width: $desktop) {
  .modal {
    &__close {
      right: 26px;
    }
    &__container {
      margin: 0;
      min-width: 95vw;
    }
  }
}
