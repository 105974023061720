@import '../../../styles/constants.scss';
@import '../../../styles/mixins.scss';

.org-user {
  &__card {
    margin-top: 32px;
  }

  &__info {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 24px 28px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 32px 0;
  }

  &__association {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    grid-gap: 24px;
    &:not(:first-child) {
      margin-top: 24px;
    }
    &--disabled {
      @include disabled();
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 20px;
    margin-left: 10px;
    > svg {
      cursor: pointer;
      margin-right: 20px;
    }
  }

  &__action-buttons {
    margin-left: auto;
    display: flex;
    > button {
      margin-right: 16px;
    }
  }

  .admin-page__header {
    margin: 0;
  }

  &__tfa {
    display: inline-flex;
    flex-direction: column;
    gap: 6px;
  }
}

@media only screen and (max-width: $desktop) {
  .org-user {
    &__header {
      flex-direction: column;
      align-items: baseline;
    }
    &__action-buttons {
      margin-left: 0;
      display: flex;
      margin-top: 12px;
      margin-bottom: 24px;
    }
    &__info {
      grid-template-columns: none;
    }
    &__association {
      grid-template-columns: none;
    }
    &__actions {
      margin-left: 0;
    }
  }
}
