@import '../../../styles/constants';

.uw-hub-page__credit-reports {
  margin-top: 32px;

  &-content {
    width: 100%;
  }

  &-applicant:first-of-type {
    margin-bottom: 12px;
  }

  &-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;

    > span {
      font-size: 16px;
      font-weight: 600;
    }

    > div > button:last-of-type {
      margin-left: 12px;
    }
  }

  &-scores {
    display: flex;
    flex-direction: row;

    > div {
      display: flex;
      flex-direction: column;
      min-width: 240px;

      > span:first-of-type {
        font-size: 14px;
        color: $colorLabel;
        font-weight: 500;
      }

      > span:last-of-type {
        font-size: 16px;
        font-weight: 500;
      }

      &:first-of-type {
        margin-right: 16px;
      }
    }
  }
}

@media only screen and (max-width: $tablet) {
  .uw-hub-page__credit-reports {
    margin-top: 16px;

    &-header {
      flex-direction: column;
      align-items: normal;

      > div > button {
        width: 100%;
        margin-top: 12px;

        &:last-of-type {
          margin-left: 0;
        }
      }
    }

    &-scores {
      flex-direction: column;

      > div {
        margin-bottom: 12px;

        &:first-of-type {
          margin-right: 0;
        }
      }
    }
  }
}
