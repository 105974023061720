.lender-agreement {
  width: 100%;

  .membership-card {
    pointer-events: all;
  }

  .lender-agreement-card {
    pointer-events: all;
  }

  .membership-modal {
    max-width: 800px;
    font-size: 0.875rem;
    line-height: 1.5;

    .applicant-info__modal-text {
      max-height: 500px;
    }
  }

  .flow__buttons .button__text {
    padding: 6px 14px;
  }

  .custom-membership-modal {
    max-width: 800px;
    text-align: left;
    .modal__container-content {
      height: 80vh;
      overflow: hidden;
      margin-bottom: 0;
    }

    .modal__title {
      padding-top: 20px;
      font-size: 1.25rem;
      font-weight: bold;
    }

    .modal__close {
      top: 30px;
      right: 30px;
    }
  }
}
