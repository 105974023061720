@import '../../styles/constants';

.global-loader {
  box-shadow: none;
  background: transparent;
  max-width: unset;

  @media screen and (min-width: $lg) {
    padding-left: 312px;
  }

  .loader {
    margin: 0 auto;
  }

  .modal {
    &__container-content {
      overflow-y: hidden;
    }
  }
}

.global-notifications {
  position: fixed;
  top: 0;
  right: 50px;
  width: calc(100% - 400px);
  z-index: $zIndexOverMenu;
  display: grid;
  justify-items: end;
}

.global-notification {
  margin-top: 15px;
  width: 100%;
  z-index: $zIndexOverMenu;
  box-sizing: border-box;
  border-radius: 12px;
  border: 1px solid;
  position: relative;
  padding: 0;
  font-size: 0.9rem;
  line-height: 1.2;
  font-weight: 600;
  display: flex;
  align-items: center;
  background: white;

  &--hoverable {
    &:hover {
      cursor: pointer;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
  }

  &__content {
    display: flex;
    padding: 10px 10px 8px 10px;
  }

  &__links {
    display: flex;
    align-items: center;
  }

  &__learn-more {
    font-size: 0.9rem;
    margin-right: 12px;
    margin-top: 12px;
    font-weight: bold;
    &--positive {
      color: $colorNotifLearnMoreSuccess;
    }
    &--negative {
      color: $colorNotifLearnMoreNegative;
    }
    &--warning {
      color: $colorNotifLearnMoreWarning;
    }
  }

  &__view-changes {
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    margin-top: 12px;
    font-weight: bold;
    &--positive {
      color: $colorNotifTextPositive;
    }
    &--negative {
      color: $colorNotifTextNegative;
    }
    &--warning {
      color: $colorNotifTextWarning;
    }
  }

  &__close {
    position: absolute;
    right: 13px;
    top: 12px;
    cursor: pointer;
    color: $grey;
    font-size: $fontSizeSecondary;
    line-height: 1;
    &--positive {
      color: $colorSuccessV2;
    }
    &--negative {
      color: $colorNotifTextNegative;
    }
    &--warning {
      color: $colorNotifTextWarning;
    }
  }

  &--positive {
    border-color: $colorSuccessV2;
    color: $colorNotifTextPositive;
    background: $colorNotifBackgroundPositive;
  }

  &--negative {
    border-color: $colorNotifBorderNegative;
    color: $colorNotifTextNegative;
    background: $colorNotifBackgroundNegative;
  }
  &--warning {
    border-color: $colorNotifBorderWarning;
    background: $colorNotifBackgroundWarning;
    color: $colorNotifTextWarning;
  }
}

.otp-input::placeholder {
  color: $borderPrimary;
}

@media screen and (max-width: $lg) {
  .global-notifications {
    width: 100%;
    right: 0;
    padding: 0 9px 9px;
  }
  .global-notification {
    &__text {
      margin-top: 2px;
    }
  }
}
