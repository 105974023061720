@import '../../../styles/constants';

.label {
  font-size: 0.75rem;
  font-weight: 500;
  color: $grey600;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.headerTitle {
  font-size: 1.125rem;
  font-weight: bold;
}

.generalInfo {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 16px 24px;
  width: 100%;
}

.cardTitle {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.loanFailedTitle {
  display: flex;
  padding: 16px;
  padding-left: 0;
  align-items: center;
}

.ausDetailsTitle {
  font-size: 1.125rem;
}

.loanFailedTitleWrapper {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
}

.loanFailedCards {
  display: flex;
  justify-content: space-between;
}

.loanFailedCard {
  border-radius: 8px;
  border: 1px solid $lighterGrey;
  background: $white;
  padding: 16px 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 300px;
  box-shadow: $redesignBoxShadow;
  &:first-child {
    margin-right: 20px;
  }
}

.loanFailedCardText {
  color: $grey600;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
}

.loanFailedCardTextWrapper {
  display: flex;
}

.loanFailedCardValue {
  color: $black;
  margin-top: 10px;
  font-weight: 600;
}

.cardButtons {
  display: flex;
  align-items: center;
  margin-left: 4px;
  gap: 128px;
}

.selectLoanWrapper {
  width: 100%;
}

.cardsWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  margin: 24px 0;
}

.reEvaluate {
  margin: 24px 0;
  display: flex;
  width: 100%;
}

.cardTitleWrapper {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: $tablet) {
  .cardsWrapper {
    grid-template-columns: 1fr;
  }

  .generalInfo {
    grid-template-columns: 1fr;
  }

  .loanFailedCards {
    flex-direction: column;
  }

  .loanFailedCard {
    margin-bottom: 12px;
  }

  .headerTitle {
    margin-bottom: 16px;
    width: 100%;
    text-align: center;
  }

  .cardButtons {
    flex-wrap: nowrap;
    gap: 0px;
  }

  .header {
    justify-content: center;
  }
}
