@import '../dropdown/index.scss';

.dropdown--multi {
  .dropdown__item {
    justify-content: flex-start;
  }

  &__actions {
    padding: 10px;
    display: flex;
    justify-content: space-evenly;
    border-bottom: 1px solid $colorCardBorder;
    color: $colorSuccessSecondary;
    margin-bottom: 10px;

    span {
      cursor: pointer;
    }
  }

  .dropdown__selected {
    display: flex;
    align-items: center;
    svg {
      height: 10px;
      width: 13px;
      margin-top: 1px;
      path {
        fill: $colorSuccessSecondary;
      }
    }
  }

  &__has-items {
    > span {
      margin-left: 6px;
    }
  }
}
