@import '../../styles/constants.scss';

.applications-page {
  padding: 0 32px;

  .table {
    margin-top: 10px;
  }

  .tab-selector__tab {
    padding: 4px 20px;
  }

  &__export {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    font-size: 0.875rem;
    color: $colorButton;
    font-weight: bold;

    &__inner {
      align-items: center;
      display: flex;
      cursor: pointer;
      &:last-child {
        margin-left: 30px;
      }
    }

    svg {
      width: 30px;
      height: 30px;
      margin-left: -10px;
      path {
        stroke: $colorButton;
      }
      rect {
        fill: $white;
      }

      &.applications-page__clipboard {
        width: 17px;
        height: 17px;
        margin-right: 3px;
      }
    }
  }

  .admin-page__container {
    padding: 30px 15px;
  }
}

.loans-card {
  display: none;
}

.sort-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  &__arrow {
    margin-left: 5px;
    height: 13px;
    width: 13px;

    &--up {
      transform: rotate(180deg); /* Equal to rotateZ(45deg) */
    }
  }
}

.search-date-filters {
  display: flex;

  &--date {
    margin-right: 8px;
  }
}

.add-new {
  margin-top: 40px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &__create {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__icon {
    height: 25px;
    width: 25px;
    margin-right: 10px;
  }

  &__text {
    font-size: $fontSize;
    font-weight: 600;
  }

  &__button {
    text-align: end;

    .button {
      &__text {
        padding: 0 30px;
        display: flex;
        align-items: center;
        font-size: 0.875rem;
      }
    }
  }
}

.tag {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  height: 24px;
  line-height: 24px;
  font-size: 11px;
  margin: 2px 5px 2px 12px;
  padding: 0 10px 0 12px;
  background: $colorButtonHover;
  color: $white;
  text-decoration: none;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;

  &:before {
    content: '';
    float: left;
    position: absolute;
    top: 0;
    left: -12px;
    width: 0;
    height: 0;
    border-color: transparent $colorButtonHover transparent transparent;
    border-style: solid;
    border-width: 12px 12px 12px 0;
  }
  &:after {
    content: '';
    position: absolute;
    top: 10px;
    left: 0;
    float: left;
    width: 4px;
    height: 4px;
    border-radius: 2px;
    background: $white;
  }
}

.no-data {
  display: flex;
  justify-content: center;
  padding: 80px 0;
}

@media only screen and (max-width: $desktop) {
  .applications-page .table {
    display: none !important;
  }

  .loans-card {
    display: block;
    margin-top: 20px;
    padding: 0;
    border-radius: 25px;

    > div {
      padding: 0;
      border-radius: 25px;
    }

    &__header {
      height: 20px;
      color: $black;

      border-radius: 25px 25px 0 0;
      background-color: $colorNotesBorder;
      font-weight: 500;

      &--bold {
        font-weight: 600;
      }
    }

    &__flex {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 12px 25px;
      color: $black;
    }

    &__blue-background {
      background-color: $selectedBackground;
    }
  }

  .add-new__create {
    font-size: 0.875rem;
  }
}

.applications-page {
  .tab-selector {
    margin: 20px auto 0;
  }
}

.cell-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  &:hover {
    text-overflow: clip;
    white-space: normal;
    word-break: break-all;
  }
}

@media only screen and (max-width: $tablet) {
  .card.loans-card {
    .card__content {
      .tag {
        right: -20px;
        top: 28px;
      }
    }
  }

  .search-input {
    display: flex;
    width: 100%;
    & > div {
      width: 100%;
    }
  }

  .search-name {
    flex-direction: column;
  }

  .search-date-filters {
    display: grid;
    grid-template-rows: auto auto;
    grid-row-gap: 16px;
    margin-top: 16px;
    width: 100%;

    &--date {
      margin-right: 0;
      & > button {
        width: 100%;
      }
    }

    &--filters {
      & > button {
        width: 100%;
      }
    }
  }
}
