@import '../../styles/constants.scss';
@import '../../styles/mixins.scss';

.dropdown {
  position: relative;
  width: 100%;
  border-bottom: 1px solid $grey;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  min-height: 12px;

  .error-wrapper {
    height: 24px;
  }

  &--no-border {
    border: none;
  }

  &__label {
    font-family: inherit;
    font-weight: 600;
    font-size: $fontSize;
    line-height: 1;
    color: $colorLabel;
    margin-bottom: 18px;

    &--bigger {
      color: $checkboxLabel;
      font-size: 1.25rem;
      font-weight: bold;
    }
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    &--has-error .dropdown {
      &:after {
        @include inputError();
      }

      .dropdown__selected {
        padding-right: 32px;
      }
    }

    &--disabled {
      cursor: default;
      .dropdown {
        @include disabled();
        opacity: 0.5;
      }
      .dropdown__btn {
        cursor: default;
      }
    }
  }

  &__btn {
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    font-family: inherit;
    line-height: 1;
    padding: 3px;
    font-size: inherit;
    height: inherit;
  }

  &__content {
    width: auto;
    position: absolute;
    top: 110%;
    left: 0;
    background: $white;
    box-shadow: $blueishBoxShadow;
    border-radius: $borderRadius;
    min-width: 100%;
    max-height: 400px;
    overflow-y: scroll;
    z-index: $zIndexOverlay;
    overflow-x: hidden;

    .dropdown__search {
      display: none;
      border: 0;
      padding: 20px 20px 10px;
      border-bottom: 1px solid $colorCardBorder;
      font-family: inherit;
      color: $black;
      width: 100%;
    }

    &--expanded {
      .dropdown__search {
        display: block;
      }
    }

    &--upwards {
      top: -100%;
      transform: translateY(-100%);
    }
  }

  &__selected {
    border: none;
    position: absolute;
    padding-right: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    margin-bottom: 5px;
    box-sizing: border-box;
    &--default {
      opacity: 0.8;
      font-size: 0.8em;
    }
  }

  &__item {
    padding: 10px 20px;
    cursor: pointer;
    font-size: $fontSize;
    color: $checkboxLabel;
    margin-top: 20px;

    &:first-child {
      margin-top: 40px;
    }

    &:last-child {
      margin-bottom: 40px;
    }

    &:hover {
      background-color: $colorCardBorder;
    }
  }

  .input__prefix {
    margin-right: 20px;
  }
}

@media only screen and (max-width: $desktop) {
  .dropdown {
    &__item {
      font-size: inherit;
      margin-top: 10px;
    }

    &__label {
      font-size: inherit;
    }
  }
}
