@import '../../styles/constants';

$selectedBackground: #f2f9fc;
$itemBackground: #b6e9ff;

.loan-product {
  margin: 60px auto;
  padding: 0 110px;

  &__plus {
    text-align: end;
    margin: 20px 10px;
    &-icon {
      width: 27px;
      height: 27px;
    }
    &:hover {
      cursor: pointer;
    }
  }

  &__filters {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }

  &__textfields {
    &-wrapper {
      display: flex;
      justify-content: space-between;
      margin-top: 0;

      &--no-justify {
        margin-top: 0;
        display: flex;
      }
      &--no-margin {
        justify-content: space-between;
        display: flex;
      }
      label {
        // margin-top: 16px;
      }
    }
  }

  &__checkbox {
    &-wrapper {
      display: flex;
      margin-top: 20px;
      align-items: center;
    }
  }

  &__dropdown {
    width: 100%;
    margin-right: 20px;
  }

  &__icon {
    cursor: pointer;
    margin-right: 8px;
  }

  &__modal {
    max-height: 450px;
    overflow: scroll;
    &::-webkit-scrollbar {
      display: none; /* Hide scrollbar for Webkit browsers */
      -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
      scrollbar-width: none; /* Hide scrollbar for Firefox */
    }

    &-buttons {
      width: 100%;
      display: flex;
      margin-top: 24px;
    }
  }

  &__template {
    &-header {
      padding: 12px;
      font-size: 12px;
      border: 1px solid $lighterGrey;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      background: linear-gradient(0deg, $lighterGrey, $lighterGrey),
        linear-gradient(0deg, $lightBlue, $lightBlue);
    }
    &-content {
      &:last-child {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
      }
      background: $white;
      padding: 18px;
      border: 1px solid $lighterGrey;
      border-top: none;
      font-weight: bold;
      font-size: 14px;
    }

    &-paper {
      margin-top: 16px;
    }
  }

  &__copy-lenders {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    width: 400px;
    font-weight: 600;
    padding: 20px;
    color: $checkboxLabel;
    border: 1px solid $lighterGrey;
    border-radius: 12px;
    &:hover {
      cursor: pointer;
    }
  }

  &__templates {
    display: flex;
    flex-direction: column;

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-title {
      font-weight: bold;
    }

    &-subitems {
      display: flex;
      align-items: center;
      font-weight: 600;
      justify-content: space-between;
    }

    &-select {
      color: $white;
      font-weight: 600;
      margin: 15px;
    }
    &-items {
      display: flex;
      align-items: center;
      margin: 20px 0;
      border: 1px solid $lighterGrey;
      border-radius: 12px;
      padding: 13px;
      &--checked {
        border: 2px solid $blue;
        background: $lightestGrey;
      }
    }
    &-item {
      background: $white;
      border-radius: 25px;
      padding: 20px;
      margin: 15px;
      text-align: center;
      font-weight: bold;
      &:hover {
        cursor: pointer;
        background: $itemBackground;
      }
      &--populated {
        background: $colorSuccessSecondary;
      }
    }
    &-lenders {
      display: flex;
      flex-direction: column;
      margin-top: 30px;
      font-weight: 600;
      color: $checkboxLabel;
    }
    &-selected {
      flex: 50%;
      color: $white;
    }
    &-name {
      margin-top: 15px;
      list-style: inside;
      font-size: 0.813rem;
      &:before {
        content: '•'; /* Insert content that looks like bullets */
        padding-right: 8px;
      }
    }
  }

  &__buttons {
    display: flex;
  }

  &__paper {
    padding: 24px;
    border: 1px solid $customGrey;
    border-radius: 12px;
    background: $white;
    margin-bottom: 32px;
    box-shadow: $redesignBoxShadow;
  }

  &__general {
    flex: 100% 1;
    .searchbar__input {
      width: 100%;
    }
  }

  &__copy {
    &-select {
      margin-bottom: 32px;
      font-size: 20px;
      font-weight: 600;
    }
  }

  &__settings {
    &-form {
      margin-top: 50px;
    }
  }

  &__content {
    padding: 0 0 40px;
  }

  &__create {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: bold;
  }

  &__lenders {
    margin-top: 20px;
    .search-module {
      margin: 0 auto 40px;
    }
  }

  &__history {
    .modal {
      position: inherit;
      background: transparent;
    }

    &-form {
      max-width: 80%;
      margin: 0 auto 20px auto;
      .applicant-info {
        &__form {
          &-general {
            margin: 0;
            border-radius: 25px 0 0 25px;
            padding-bottom: 20px;
            padding-top: 20px;
          }
          &-address {
            border-radius: 0 25px 25px 0;
          }
        }
      }
    }
  }

  &__plus-icon {
    margin-right: 10px;
  }

  &__searchbar {
    margin-bottom: 20px;
    &--edit {
      margin-top: 20px;
    }
  }

  &__description {
    background: $selectedBackground;
    padding: 20px;
  }

  &__selector {
    margin-top: 20px;
    padding: 0 32px;
  }

  &__financial {
    margin-top: 24px;
    margin-bottom: 12px;
    padding: 24px;
    border-radius: 8px;
    background: var(--color-gray-50, $lightBlue);
    &-title {
      color: var(--gray-900, var(--color-gray-900, #101828));

      font-size: 0.9rem;
      margin-bottom: 24px;
      font-weight: 500;
    }
  }
  &__textfield {
    width: 100%;
    margin-top: 0;
    margin-right: 28px;
  }

  &__date {
    &-created {
      background: $white;
      padding: 20px;
      display: flex;
      justify-content: space-between;
    }
    &-updated {
      background: $selectedBackground;
      padding: 20px;
      display: flex;
      justify-content: space-between;
      &--no-margin {
        margin-bottom: 0;
      }
    }
  }
  &__wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  }
  &__loan-status {
    background: $white;
    padding: 20px;
    display: flex;
    justify-content: space-between;
  }
  &__commercial-product {
    background: $selectedBackground;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
  }

  .modal {
    &__container {
      overflow-y: auto;
      overflow-x: hidden;
      max-width: 70%;
      max-height: 80%;
    }
    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-track {
      background: $white;
    }
  }

  &__modal {
    &:hover {
      cursor: pointer;
    }
    .card {
      display: flex;
      justify-content: center;
      padding: 0;
      margin-top: 15px;
      &__content {
        padding: 0;
        border-top: 0;
      }
    }
  }
}

@media only screen and (max-width: $desktop) {
  .loan-product {
    &__textfields {
      &-wrapper {
        margin-top: 0;
        flex-wrap: wrap;
        &--no-justify {
          margin-top: 0;
        }
      }
    }
    &__templates {
      &-header {
        flex-direction: column;
        align-items: start;
      }
      &-title {
        margin-bottom: 16px;
      }
    }
    &__dropdown {
      margin-top: 12px;
      margin-right: 0;
    }
    &__header {
      margin-top: 16px;
      flex-direction: column;
      align-items: inherit;
      div {
        margin-bottom: 12px;
      }
    }
    &__paper {
      padding: 0;
      border: none;
      background: none;
      box-shadow: none;
    }
    &__modal {
      overflow: scroll;
      min-width: inherit;
      &-buttons {
        display: flex;
        flex-direction: column;
        &:first-child {
          margin-bottom: 10px;
        }
      }
    }
    &__searchbar {
      margin-bottom: 20px;
      &--edit {
        margin-top: 0;
      }
    }
    &__template {
      &-content {
        padding: 12px;
        font-size: 12px;
      }
    }
    &__selector {
      padding: 0 16px;
    }
    &__filters {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    &__financial {
      padding-bottom: 0;
    }
    &__textfield {
      width: 100%;
      margin-top: 16px;
      margin-right: 0;
    }
  }
}
