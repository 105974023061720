@import '../../../styles/constants';

.delete-modal {
  &__title {
    margin-top: 5px;
    text-align: left;
    font-weight: 600;
    font-size: 1.25rem;
    color: $colorLabel;
    margin-bottom: 30px;
  }

  &__question {
    color: $colorLabel;
    text-align: left;
    font-weight: 500;
    line-height: 1.5;
  }

  &__all-actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 100px;
  }

  &__action {
    margin-right: 10px;
    .button__text {
      padding: 6px 15px;
    }
    > div {
      font-weight: 500;
      font-size: 0.9rem;
    }
  }

  &__delete {
    background-color: $colorDelete;
    margin-right: 20px;

    &:hover {
      background: $colorHoverDelete;
      cursor: pointer;
    }
  }

  &__cancel {
    background-color: $grey;

    &:hover {
      background: $colorEmail;
      cursor: pointer;
    }

    > div {
      color: $black;
    }
  }
}
