@import '../../styles/constants.scss';

$darkGrey: #1d2939;

.form {
  display: flex;
  flex-direction: column;

  &__dropdown {
    width: 100%;
  }

  &__input {
    margin-top: 24px;
  }

  &__html {
    margin-top: 12px;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    color: $darkGrey;

    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__heading {
    font-size: 1.25rem;
    font-weight: bold;
    color: $black;
    margin-top: 12px;
  }
}

.form-row {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
}

.form-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  margin-bottom: 12px;
  gap: 1rem;
  border-top: 1px solid $lighterGrey;
  padding: 24px 24px 12px 24px;

  &__glendale {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 12px;
    border-top: 1px solid $lighterGrey;
    padding: 24px;
  }
}

.form-text {
  font-size: 0.875rem;
}

.sticky {
  bottom: 0;
  position: sticky;
  background: $white;
  z-index: 5;
  padding-bottom: 0px;
  border-radius: 12px;
}

.full-button {
  width: 100%;
  margin-bottom: 0px;
}

.padding-24 {
  padding: 12px 24px 0;
}

.margin-top-24 {
  margin-top: 24px;
}

.margin-top-12 {
  margin-top: 12px;
}

.hidden {
  display: none;
}

@media only screen and (max-width: $tablet) {
  .form-buttons {
    flex-direction: column;
    gap: 12px;
    margin-bottom: 0;
    margin-top: 16px;
    padding: 16px;

    &__glendale {
      flex-direction: column;
      gap: 12px;
      margin-top: 0;
      padding: 16px;
    }
  }

  .form {
    &__html {
      margin-top: 12px;
    }

    &__input {
      margin-top: 12px;
    }
  }

  .form-row {
    flex-direction: column;
    gap: 0;
  }

  .padding-24 {
    padding: 12px 16px 0;
  }

  .margin-top-24 {
    margin-top: 12px;
  }
}
