@import '../../styles/constants.scss';

.RichEditor-controls {
  margin-bottom: 0;
  background-color: $white;
  border: 0.5px solid $colorButton;
  padding-left: 5px;
  &:first-child {
    border-bottom: none;
    border-radius: 10px 10px 0 0;
  }

  &:last-child {
    border-top: none;
  }
  user-select: none;
}

.RichEditor-styleButton {
  display: inline-block;
  button {
    border: none;
    color: $colorButton;
    background-color: $white;

    &:hover {
      cursor: pointer;
    }
  }

  &--active {
    button {
      background-color: $colorButton;
      border-radius: 10px;
      color: $white;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.DraftEditor-editorContainer {
  background: $white;
  border: 0.5px solid $colorButton;
  border-top: none;
  box-sizing: border-box;
  border-radius: 0 0 10px 10px;
  min-height: 66px;
  width: 100%;
  resize: none;
  padding: 16px;
  font-family: inherit;
  font-size: 0.875rem;
  line-height: 1.2;
}
