@import '../../../styles/constants';

$darkBlue: #daecf4;

.wires {
  &__actions > div {
    cursor: pointer;
    margin-right: 8px;
  }

  .afp__expanded [role='columnheader'] {
    background: $darkBlue;
  }

  &__footer {
    border-radius: 12px;
    border: 1px solid $lighterGrey;
    margin-top: 10px;
    overflow: hidden;

    p {
      margin: 0;
      padding: 16px 13px;
      height: 42px;
      font-size: 0.75rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    p.wires__footer__total {
      background: $lightBlue;
      font-weight: 500;
    }
  }
}
