@import '../../../styles/constants';

.ntp__documents {
  width: 100%;

  &-wrapper {
    display: grid;
    gap: 16px;
    margin-top: 0;
  }
}

@media only screen and (max-width: $tablet) {
  .ntp__documents-wrapper {
    margin-top: 0;
  }
}

@media only screen and (max-width: $mobileLarge) {
  .ntp-upload {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
}
