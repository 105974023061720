@import '../../styles/constants';

.cardsGrid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 24px;

  & > div {
    grid-column: span 4;
  }
  & > div:nth-child(-n + 4) {
    grid-column: span 3;
  }
}

@media only screen and (max-width: $laptop) {
  .cardsGrid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    & > div {
      grid-column: auto;
    }
    & > div:nth-child(-n + 4) {
      grid-column: auto;
    }
  }
}

@media only screen and (max-width: $mobile) {
  .cardsGrid {
    grid-template-columns: 1fr;
  }
}
