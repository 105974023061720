@import '../../../../styles/constants.scss';

.loan-token {
  &__label {
    max-width: 220px;
    min-width: 220px;
    margin-right: 10px;
    overflow: hidden;
    overflow-wrap: break-word;
  }

  &__dropdown {
    width: 200px;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__fields {
    display: flex;
    justify-content: flex-start;
  }

  &__key {
    font-weight: 600;
    font-size: 1.25rem;
    margin-bottom: 24px;
  }
}

@media only screen and (max-width: $desktop) {
  .loan-token {
    .input {
      margin: 10px 0px;
    }

    &__dropdown {
      width: 90vw;
      margin-bottom: 16px;
    }

    &__fields {
      flex-direction: column;
    }
  }
}
