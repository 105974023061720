@import '../../../../styles/constants';

.loan-blocked {
  &__text {
    color: $darkerGray;
    font-size: 0.875rem;
  }
  &__option {
    display: flex;
    align-items: flex-start;
    padding: 24px 0;

    &--contact {
      align-items: center;
    }

    &__title {
      font-weight: 500;
      color: $primary900;
    }
    &__action {
      margin-left: auto;
      align-self: center;
      padding-left: 40px;
      text-align: right;
      flex-grow: 1;
      flex-shrink: 0;
    }
    &__svg {
      border-radius: 10px !important; // This gets overridden by the IconButton component
      margin-right: 16px !important; // This gets overridden by the IconButton component
      width: 48px;
      height: 48px;
    }
  }
  &__link {
    color: $primary700;
    font-weight: 600;
    font-size: 0.875rem;
    cursor: pointer;
  }
}
