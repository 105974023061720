@import './constants';

@mixin linkStyles {
  color: $primary700;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: $colorButton;
  }
}

@mixin disabled {
  opacity: 0.7;
  pointer-events: none;
  cursor: default;
}

@mixin notDisabled {
  pointer-events: all !important;
  opacity: 1 !important;
  cursor: pointer !important;
}

@mixin flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin inputError {
  content: '';
  background: url('/assets/error-icon.svg') no-repeat;
  width: 15px;
  height: 14px;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

@mixin styledScrollbar {
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $colorScroll;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $grey;
  }
}
