@import '../../styles/constants';
@import '../../styles/mixins';

.search-module {
  margin: 40px 0 0;

  &--no-margin {
    margin: 0;
  }

  &__filters {
    margin: 20px auto 0;
    width: auto;
    display: flex;
    justify-content: space-around;
    font-size: $fontSizeSecondary;
    align-items: center;
    min-width: 51%;
    flex-wrap: wrap;

    > span {
      color: #c4c4c4;
      flex-shrink: 0;
      margin-right: 10px;
      margin-top: 10px;
    }

    .dropdown {
      border: 1px solid #e2e2e2;
      border-radius: 40px;
      padding: 0;
      margin-left: 10px;
      width: 200px;

      &__wrapper {
        min-width: 180px;
        width: auto;
        margin-top: 10px;
      }

      &__selected {
        margin: 0;
        width: 100%;
        padding-right: 5px;
        position: relative;
        .filter__date {
          svg {
            margin-right: 6px;
            margin-left: 0;
          }
          path {
            fill: $colorSuccessSecondary;
          }
        }
      }
      &__btn {
        padding: 8px 12px 8px 17px;
        overflow: hidden;
      }
      &__item {
        font-size: $fontSizeSecondary;
        padding: 8px 10px;
        &:first-child {
          margin-top: 25px;
        }
        &:last-child {
          margin-bottom: 25px;
        }
      }
    }
  }

  &--no-search .search-module__filters {
    margin: 0 auto;
  }
  .flexColumn {
    flex-direction: column;
  }
  &__search {
    display: flex;
    width: 50%;
    margin: 0;

    .button {
      width: 100px;
      padding: 10px;
      &__text {
        font-size: $fontSizeSecondary;
        padding: 0;
      }
    }

    .input__wrapper {
      margin-bottom: 20px;
    }

    .error-wrapper {
      display: none;
    }

    &--full {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }

  .filter {
    &__date {
      display: flex;
      align-items: center;
      svg {
        width: 18px;
        height: 13px;
        margin-left: auto;
      }
    }

    &--selected {
      .dropdown {
        border: 1px solid $colorSuccessSecondary;
        color: $colorSuccessSecondary;
      }
      .filter__x {
        z-index: $zIndexOverlay;
        width: 8px;
        height: 8px;
        path {
          stroke: $checkboxLabel;
          stroke-width: 4;
        }
      }
    }

    &__clear {
      white-space: nowrap;
      margin-top: 8px;
    }

    &__applied {
      margin-top: 20px;
      margin-bottom: 20px;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
    }

    &__list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;
    }

    &__item {
      font-weight: normal;
      font-size: 0.75rem;
      padding: 6px 12px;
      border-radius: 8px;
      border: 1px solid #e2e2e2;
      margin-right: 10px;
      margin-top: 15px;
      display: flex;
      align-items: center;

      &:hover {
        border-color: $blue;
      }

      svg {
        width: 11px;
        height: 11px;
        cursor: pointer;
        margin-left: 7px;
        path {
          stroke: #a3b4bb;
        }
      }
    }
  }

  .dropdown--multi {
    .dropdown__content {
      width: 300px;
    }
    .dropdown__item {
      margin-top: 0;
      padding: 10px 0 10px 20px;
    }
  }
}

@media only screen and (max-width: $sm) {
  .filter {
    &__applied {
      flex-direction: column;
    }
  }
}

@media only screen and (max-width: $laptop) {
  .search-module {
    &__filters {
      justify-content: center;
    }
  }
}

@media only screen and (max-width: $desktop) {
  .search-module {
    &__filters {
      flex-direction: row;
      flex-wrap: wrap;
      min-width: 100%;

      > div {
        width: 180px;
        margin-bottom: 30px;
      }

      > span {
        width: 100%;
        margin-bottom: 20px;
        text-align: center;
      }
    }
    &__search {
      width: 100%;
    }
  }
}

// for external use, to enable
.small-width-context-filters {
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 100%;

  > div {
    width: 180px;
    margin-bottom: 30px;
  }

  > span {
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
  }
}

.small-width-context-organization {
  width: 80%;
}

.clearButton {
  & > path {
    stroke: $primary700;
  }
}
