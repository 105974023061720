@import '../../../styles/constants.scss';

.uw-hub-page__stipulations {
  margin-top: 32px;

  &-documents {
    width: 100%;
  }

  &-actions {
    margin-bottom: 12px;
    display: flex;
  }
}

@media only screen and (max-width: $desktop) {
  .uw-hub-page__stipulations {
    margin-top: 16px;
  }
}

@media only screen and (max-width: $tablet) {
  .uw-hub-page__stipulations-actions {
    flex-direction: column;
    align-items: flex-start;
  }
}
