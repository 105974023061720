@import '../../../styles/constants';

.stipulations {
  &__title {
    font-size: 30px;
    font-weight: bold;
    color: black;
  }
  &__buttons {
    display: flex;
    align-items: center;
    width: inherit;
    margin-top: 0;
  }
  &__template {
    width: 100%;
  }
  &__textfields {
    display: flex;
    max-width: 950px;
  }
  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 33px;
  }
  &__icons {
    display: flex;
    align-items: center;
    margin-left: 24px;
    &-wrapper {
      display: flex;
      flex-direction: column;
    }
  }
  &__element {
    display: flex;
    align-items: center;
    border: 1px solid #eaecf0;
    box-shadow: 0px 1px 2px 0px #1018280f;
    border-radius: 12px;
    padding: 24px 16px;
    gap: 10px;
    margin-bottom: 32px;
  }
  padding: 0 0 40px;
}

@media only screen and (max-width: $tablet) {
  .stipulations {
    &__wrapper {
      flex-wrap: wrap;
    }
    &__buttons {
      width: 100%;
      margin-top: 12px;
    }
    &__textfields {
      flex-direction: column;
    }
    &__icons {
      margin-bottom: 16px;
      margin-left: 0;
    }
  }
}
