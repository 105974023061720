@import '../../../../../../styles/constants.scss';

.label {
  font-size: 14px;
  font-weight: 500;
  color: $colorLabel;
}

.salesRepCard,
.borrowerCard {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
}

.generalCard {
  @extend .salesRepCard;
  font-size: 14px;
  font-weight: 500;
}

@media only screen and (max-width: $tablet) {
  .generalCard,
  .borrowerCard,
  .salesRepCard {
    grid-template-columns: 1fr;
  }
}
