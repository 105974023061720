@import '../../../../styles/constants.scss';

$selectedBackground: #f2f9fc;
$infoColor: #344054;
.credit-reports {
  &__info {
    color: $infoColor;
    font-size: 1rem;
    font-weight: 500;
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
  }

  &__wrapper {
    margin-top: 8px;
  }

  &__flex {
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-weight: 700;
    font-size: 0.875rem;
    color: $black;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    margin-top: 0;
  }

  &__data {
    max-width: 800px;
  }

  &__item {
    color: $colorLabel;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 500;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    text-wrap: nowrap;
    align-items: baseline;
    gap: 12px;
  }

  &__paper {
    padding: 24px;
    border: 1px solid $customGrey;
    border-radius: 12px;
    background: $white;
    margin-bottom: 32px;
    box-shadow: $redesignBoxShadow;

    display: flex;
    margin-top: 24px;
    flex-wrap: wrap;

    &-wrapper {
      display: flex;
      flex-direction: column;
      width: 33%;

      &--no-flex {
        margin-top: 24px;
        display: block;
      }
    }
  }
}

@media only screen and (max-width: $desktop) {
  .credit-reports {
    &__buttons {
      justify-content: center;
      flex-direction: column;
    }

    &__header {
      display: none;
    }

    &__info {
      display: flex;
      flex-direction: row;
    }

    &__paper {
      flex-direction: column;
      padding: 12px;

      &-wrapper {
        margin-top: 8px;
        width: 100%;
        span {
          margin-right: 12px;
        }
        &--no-flex {
          margin-top: 8px;
        }
      }
    }
  }
}
