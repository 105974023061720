@import '../../../../styles/constants.scss';

.identity-verification-modal {
  max-width: 900px;
}

.colors {
  &--red {
    color: $colorError;
  }

  &--green {
    color: $colorSuccessSecondary;
  }
}

.applicant-verification {
  &__header {
    display: flex;
    justify-content: space-between;
    background: $lightBlue;
    padding: 16px 12px;
    align-items: center;
    border: 1px solid $lighterGrey;
    border-radius: 12px 12px 0 0;
    &-item {
      color: $grey600;
      font-size: 0.75rem;
      font-weight: 500;
      width: 23%;

      &--no-height {
        height: auto;
      }
    }
  }
  &__padding {
    margin-top: 16px;
  }
  &__content {
    display: flex;
    justify-content: space-between;
    padding: 32px 16px;
    border: 1px solid $lighterGrey;
    border-radius: 0 0 12px 12px;
    border-top: 0;
    &-item {
      color: var(--gray-900, var(--color-gray-900, var(--color-gray-900)));
      font-size: 0.875rem;
      font-weight: 500;
      width: 23%;
    }
  }
}

.side-applicant {
  &__title {
    color: $black;
    text-align: left;
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 12px;
  }
  &__key {
    &-title {
      text-align: left;
      color: $colorLabel;
      font-size: 1rem;
      display: flex;
      align-items: center;
    }
    &-message {
      color: $black;
      margin-top: 8px;
      font-size: 0.875rem;
      font-weight: 500;
      max-width: 135px;
    }
    &-wrapper {
      display: flex;
      justify-content: space-between;
    }
  }
  &__paper {
    padding: 24px;
    border: 1px solid $customGrey;
    border-radius: 12px;
    background: $white;
    margin-top: 16px;
    margin-bottom: 32px;
    box-shadow: $redesignBoxShadow;
  }
}

.id-verification-item {
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-weight: 600;
  font-size: 0.75rem;

  height: 40px;
  padding: 0 10px;
  color: #46535a;

  border-radius: 25px;

  &__title {
    padding-bottom: 20px;
  }
}

.differentiator {
  background-color: $selectedBackground;
}

.margin-bottom-separator {
  margin-bottom: 40px;
}

.item-overflow {
  line-height: 20.5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100px;
}

@media only screen and (max-width: $desktop) {
  .applicant-verification {
    &__header {
      flex-direction: column;
      align-items: normal;
      text-align: left;
      &-item {
        width: 100%;
        padding: 12px;
        height: 42px;
        text-align: left;
        border: 1px solid $lighterGrey;
      }
    }
    &__content {
      display: flex;
      flex-direction: column;
      padding: 0;
      &-item {
        height: 42px;

        &--no-height {
          height: auto;
        }
      }
    }
  }
  .side-applicant {
    &__key {
      &-wrapper {
        flex-direction: column;
      }
      &-title {
        margin-top: 12px;
      }
    }
  }
}
