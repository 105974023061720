@import '../../../../styles/constants';
@import '../../../../styles/mixins';

.check-list {
  width: 100%;
  color: $black;

  &__item {
    justify-content: space-between;
    margin: 0;
    padding: 24px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    &:first-child {
      border-top: 0;
    }
  }

  &__subcheck {
    margin-left: 4px;
    margin-top: 15px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  &__trash {
    margin-left: 20px;
    &:hover circle {
      fill: $colorButtonHover;
    }
  }
}

.reason-wrapper {
  font-size: 14px;
  margin-left: 8px;
}

.reason-row__title {
  font-weight: 600;
  color: $black;
}

@media only screen and (max-width: $desktop) {
  .check-list {
    &__icon {
      background-repeat: no-repeat;
      min-width: 20px;
      min-height: 20px;
    }
  }
}

@media only screen and (max-width: $tablet) {
  .check-list {
    &__item {
      flex-wrap: wrap;
    }

    &__subcheck {
      margin-left: 16px;
      margin-top: 16px;
    }
  }
}

@media only screen and (max-width: $mobile) {
  .check-list {
    &__item {
      padding: 16px;
    }
  }
}
