@import '../../../../styles/constants';

.afp__dam {
  &__inputs {
    position: relative;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: 16px;
  }

  &__days {
    width: 45%;
    margin-right: 4%;
  }

  &__fee {
    width: 45%;
  }

  &__minus {
    margin-left: auto;
    margin-top: 35px;
    cursor: pointer;
  }

  .error {
    width: 100%;
  }

  .input__wrapper {
    margin-top: 10px;
  }

  .input__label {
    font-size: 0.75rem;
  }

  &__subtitle {
    font-weight: 500;
    font-size: 0.875rem;
    margin-bottom: 10px;
  }
}
